import { state1 } from './../../colors';

export const inputContainer = {
    width:'100%',
    paddingRight:'100px'
}

export const iconButtonSearch = {
    position:'absolute',
    top:'0',
    right:'0px',
    bottom:'0px',
    padding:'14px',
    backgroundColor:state1.colors.customBlue,
    color:state1.colors.white,
    borderTopRightRadius:'8px',
    borderBottomRightRadius:'8px',
    borderTopLeftRadius:'0px',
    borderBottomLeftRadius:'0px',
    '&:hover':{
        backgroundColor:state1.colors.customBlue,
        color:state1.colors.white,
    }
}

export const closeIcon = {
    position:'absolute',
    right:'20px',
    top:'50%',
    transform:'translateY(-50%)',
    color:state1.colors.customGray,
    transition:'0.3s',
    cursor:'pointer',
    '&:hover':{
     color:state1.colors.customBlue,
    }
}

export const extraCardContainer = {
    display: 'grid', 
    gridTemplateColumns: '1fr 1fr',
    gap:'18px',
    marginTop:'32px',
    '@media (max-width: 768px)' : {
        gridTemplateColumns: '1fr',
    },
}