import { state1 } from "../../colors";

export const custtomDropDownstyle = {
    background:state1.colors.white,
    padding:'10px',
    position:'absolute',
    top:'30px',
    borderRadius:'8px',
    '.languageBlock:hover':{
        color:state1.colors.customBlue
    }
}

export const custtomDropDownBlockStyle = {
    cursor: 'pointer',
    transition:'0.3s',
    '.customLngBlock, .arrowUp, .arrowDown':{
        transition:'0.3s',
    },
    '&:hover': {
        '.customLngBlock, .arrowUp, .arrowDown':{
            color:state1.colors.customBlue
        }
    },
}
