import { Box } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { getPosts } from "../../api/headerApis";
import { CustomOpenedMenuText } from "../../customText";
import { Flex } from "../../models/boxes";
import AnalysesCard from "../analysesCard/analysesCard";
import CardComponent from "../card/card";
import Loader from "../loader";
import SpringCard from "../springCard/springCard";
import { loaderHolder, definedComponent } from "./style";

export const DefineComponent = ({ incomingComponent }) => {
  const [data, setData] = useState(null);
  const [cardType, setCardType] = useState("string1");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();

    setIsLoading(true);
    const fetchData = async (type, id) => {
      setData(null);
      const { docs } = await getPosts(
        id,
        2,
        undefined,
        undefined,
        undefined,
        controller.signal
      );
      setCardType(type);
      setData(docs);
      setIsLoading(false);
    };
    if (incomingComponent?.subTexts) {
      setCardType("string1");
      setData(incomingComponent.subTexts);
      setIsLoading(false);
    }

    if (
      incomingComponent &&
      !incomingComponent.custom_text &&
      incomingComponent?.cardType?.card_type.includes("Type 1")
    ) {
      fetchData("Type1", incomingComponent.id);
    }

    if (
      incomingComponent &&
      !incomingComponent.custom_text &&
      incomingComponent?.cardType?.card_type.includes("Type 2")
    ) {
      fetchData("Type2", incomingComponent.id);
    }

    if (
      incomingComponent &&
      !incomingComponent.custom_text &&
      incomingComponent?.cardType?.card_type.includes("Type 3")
    ) {
      fetchData("Type3", incomingComponent.id);
    }

    if (
      (incomingComponent &&
        !incomingComponent.custom_text &&
        incomingComponent?.cardType?.card_type.includes("Type 5")) ||
      incomingComponent?.cardType?.card_type.includes("Type 4")
    ) {
      fetchData("Type5", incomingComponent.id);
    }

    if (
      incomingComponent &&
      !incomingComponent.subTexts &&
      !incomingComponent.cardType
    ) {
      fetchData("string1", incomingComponent.id);
    }

    return () => {
      controller.abort();
    };
  }, [incomingComponent]);

  const generateComponent = () => {
    const stringTypes = ["string1", "string2"];
    if (stringTypes.includes(cardType)) {
      return (
        <CustomOpenedMenuText>
          {data?.[`title${i18next.language}`]}
        </CustomOpenedMenuText>
      );
    }

    if (cardType === "Type1") {
      return <SpringCard item={data[0]} />;
    }

    if (cardType === "Type2") {
      return <AnalysesCard item={data[0]} />;
    }

    if (cardType === "Type3") {
      return (
        <Flex gap={3}>
          {data?.slice(0, 2)?.map((item) => (
            <CardComponent key={item.id} item={item} cardClick={"gallery"} />
          ))}
        </Flex>
      );
    }

    if (cardType === 'Type5') {
      return (
        <CustomOpenedMenuText>
          {data?.[0]?.[`title${i18next.language}`]}
        </CustomOpenedMenuText>
      );
    }

    return null;
  };

  return (
    <>
      {isLoading ? (
        <Box sx={loaderHolder}>
          <Loader />
        </Box>
      ) : (
        <Box className="defined-component" sx={definedComponent}>{generateComponent()}</Box>
      )}
    </>
  );
};
