import { state1 } from '../../colors'

export const subscriptionBlock = {
    position:'relative',
    width:'100%',
    maxWidth:'848px',
    paddingTop:'58px',
    paddingBottom:'58px',
    background: state1.colors.white,
    boxShadow: '0px 8px 24px rgba(34, 34, 34, 0.16)',
    borderRadius:'16px'
}

export const subscriptionContent = {
    height:'100vh',
    flexDirection: 'column',
    alignItems: 'center',
    margin:'0% 5%',
}

export const subscriptionImage = {
    '@media (max-width : 768px)': {
        display:'none'
    }
}

export const subscriptionTextBlock = {
   position:'relative',
   width:'100%',
   backgroundColor:state1.colors.white,
}

export const subscriptionShadow = {
    position:'absolute',
    top:'140px',
    left:'0',
    width:'100%',
    background: 'radial-gradient(50% 50% at 50% 50%, #0A9CB5 0%, rgba(245, 248, 255, 0) 100%)',
    width:'100%',
    height:'100px',
    opacity: '0.6',
    borderRadius: '16px',
    '@media (max-width : 768px)': {
        top:'40px',
    }
}

export const subscriptionEmail = {
    width:'100%',
    maxWidth:'300px',

    "& input":{
        border:'none'
    },

    "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":{
        borderColor:state1.colors.customBlue
    }
}

export const btnStyle = {
    width:'100%',
    maxWidth:'300px',
    marginTop:'36px',
    '& > button': {
        width:'100%'
    }
}

export const link = {
    textDecoration:'underline',
    cursor:'pointer'
}