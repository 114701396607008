export const fr = {
  about: "à propos de nous",
  party: "Activité",
  projects: "Projets",
  information: "Information",
  contact: "Contact",
  subscribe: "S'abonner",
  download:"Download",
  donate: "Donner",
  seeMore: "Voir plus",
  joinUs: "Rejoignez-nous",
  program: "Programme",
  internationalRelations: "Relations internationales",
  politicalScience: "Science politique",
  militaryScience: "Science militaire",
  politicalEconomy: "Économie politique",
  socioPolitical: "Sociopolitique",
  artsakhIssue: "Question de l'Artsakh",
  diaspora: "diaspora",
  politicsProgramm: "Programme de soutien aux communautés",
  gallery: "galerie",
  news: "nouvelles",
  publications: "éditions",
  analysis: "Analyses",
  team: "notre équipe",
  read: "Lire",
  ideology: "Idéologie",
  charter: "charte",
  email: "Email",
  search: "Recherche",
  phone: "Phone",
  leaveAComment: "Laissez un commentaire",
  nameSurname: "Prénom / nom de famille",
  comment: "commentaire",
  comments: "commentaires",
  moreInfo: "Vous pouvez trouver plus d'informations ici",
  searchResult: "résultat de la recherche",
  byQuery: "sur demande",
  print: "Imprimer",
  linkCopied: "Lien copié",
  copyLink: "Copiez le lien",
  pdf: "PDF",
  subscribtion:"Abonnement",
  letsGetInTouch:"Prenons contact",
  pdfDocument: 'PDF document',
  pdfBooks: 'PDF books',
  // SEO

  Home: "Maison - Consolidation démocratique",
};
