import React from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import SpringCard from "../springCard/springCard";
import { Box } from "@mui/material";
import { springCarousel } from "./style";
import { useState } from "react";

const getTouches = (evt) => {
  return evt.touches || evt.originalEvent.touches;
};

const SpringCarousel = ({posts}) => {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    enableSwipe: true,
    config: config.gentle,
  });
  const slides = posts?.map((slide, index) => {
    return {
      key: index,
      content: <SpringCard item={slide} />,
      onClick: () => setState({ goToSlide: index }),
    };
  });

  const handleTouchStart = (evt) => {
    if (!state.enableSwipe) {
      return;
    }

    const firstTouch = getTouches(evt)[0];
    setState({
      ...state,
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY,
    });
  };

  const handleTouchMove = (evt) => {
    if (!state.enableSwipe || (!state.xDown && !state.yDown)) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = state.xDown - xUp;
    let yDiff = state.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        setState({
          goToSlide: state.goToSlide + 1,
          xDown: null,
          yDown: null,
        });
      } else {
        /* right swipe */
        setState({
          goToSlide: state.goToSlide - 1,
          xDown: null,
          yDown: null,
        });
      }
    }
  };

  return (
    <>
        <Box
        className="spring-carousel"
          sx={springCarousel}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {slides && (
            <Carousel
              offsetFn={() => {
                return {
                  opacity: .8,
                };
              }}
              slides={slides}
              goToSlide={state.goToSlide}
              offsetRadius={state.offsetRadius}
              showNavigation={state.showNavigation}
            />
          )}
        </Box>
    </>
  );
};

export default SpringCarousel;
