import background from '../../assets/images/about_us_bg.png';
import { state1 } from '../../colors';

export const aboutUsBox = {
    backgroundSize: "cover",
    backgroundImage: `url(${background})`,
    backgroundRepeat:'no-repeat',
    margin:'0 auto',
    maxWidth:'1440px',
    gap:'24px',
    position:'relative',
}

export const aboutUsBoxFooter = {
    position: 'absolute',
    bottom: 0,
    width:'100%',
    background: "linear-gradient(180deg, rgba(178, 204, 211, 0.01) 0%, rgba(255, 255, 255, 0.45) 55.21%, rgba(255, 255, 255, 0.8) 100%)",
    opacity:1,
    visibility:'visible',
    transition:'0.3s',
    paddingTop:'20px',
    paddingBottom:'20px',
    cursor:'pointer',
}

export const aboutUsBoxFooterRemove = {
    width:'100%',
    position: 'absolute',
    bottom: 0,
    opacity:0,
    visibility:'hidden',
    transition:'0.3s',
}

export const aboutUsComponent = {
    maxHeight:'465px',
    rowGap:'56px',
    px:'14.5%',
    py:'4.8%',
    justifyContent:'space-between',
    overflow:'hidden',
    '@media (max-width: 768px)' : {
        justifyContent: 'center',
    },
}

export const arrowDownIcon = {
    color:'white',
    transition:'0.3s',
    '&:hover':{
     color:state1.colors.customBlue,
    }
}