import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import i18next from "i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { CustomHeaderLinks } from "../../../customText";
import Loader from "../../loader";
import { accordionBlock, logoBlock, menuResponsiveButtons } from "../style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "../../../elements/button/button";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/Logo.png";
import { getSubmenus } from "../../../api/headerApis";

export const MainMenu = ({ open, setOpen, mainMenu, handleOpenDialog }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [subMenus, setSubMenus] = React.useState(null);
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchSubMenus = async (id) => {
    setIsLoading(true);
    const data = await getSubmenus(id);
    setSubMenus(data);
    setIsLoading(false);
  };

  const openPage = (subMenu) => {
    navigate(subMenu.pageType === "dynamic" ? subMenu.id : subMenu.pageType);
    setOpen(false);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <Link to={"/"}>
         
          <Box component="img" src={Logo} />
        
      </Link>
      {mainMenu?.map((page) => (
        <Accordion
          key={page.id}
          expanded={expanded === page.id}
          sx={accordionBlock}
          onClick={() => fetchSubMenus(page.id)}
          onChange={handleChange(page.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${page.id}a-content`}
            id={`panel${page.id}a-header`}
          >
            <CustomHeaderLinks>
              {page[`title${i18next.language}`]}
            </CustomHeaderLinks>
          </AccordionSummary>
          {isLoading ? (
            <Loader />
          ) : (
            subMenus?.map((subMenu) => {
              return (
                <AccordionDetails key={subMenu.id}>
                  <CustomHeaderLinks onClick={() => openPage(subMenu)}>
                    {subMenu[`title${i18next.language}`]}
                  </CustomHeaderLinks>
                </AccordionDetails>
              );
            })
          )}
        </Accordion>
      ))}
      <Accordion sx={accordionBlock}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <CustomHeaderLinks>{t("contact")}</CustomHeaderLinks>
        </AccordionSummary>
      </Accordion>
      <Box sx={menuResponsiveButtons}>
        <CustomButton
          onClick={() => navigate("/subscribtion")}
          className="active"
          text={t("joinUs")}
        />
        <CustomButton
          className="active"
          onClick={handleOpenDialog}
          text={t("donate")}
        />
      </Box>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      {list()}
    </SwipeableDrawer>
  );
};
