export const peopleContainer = {
    minWidth:'196px',
    textAlign:'center',
    cursor:'pointer',
    '@media (max-width: 768px)' : {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    }
}

export const peopleBox = {
    width:'145px',
    height:'145px',
    margin:'0 auto',
    borderRadius:'50%',
    overflow:'hidden',
}
