import { state1 } from "../../colors"

export const InputStyle = {
    '.MuiOutlinedInput': {
        border:`1px solid ${state1.colors.errorColor} !important`,
        borderRadius:'8px'
    },
}   

export const DividerLabel = {
    marginBottom:'52px',
}

export const CustomTextArea = {
    "& .MuiInputBase-input": {
         resize:'vertical' 
    },

    "&.error fieldset": {
        border:`1px solid ${state1.colors.errorColor}`,
    },
    
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: state1.colors.customBlue,
        },
    },
}

export const CommentPost = {
    marginBottom:'16px',
}

export const CommentPostLine = {
    maxHeight:'400px',
    padding:'12px',
    overflowY:'auto',
 }

