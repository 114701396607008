export const state1 = {
    colors:{
        customBlue:'#4FC6DF',
        customBlack:'#061F24',
        customGray:'#4B6269',
        footerBackground:'#009FB8',
        white:'#FFFFFF',
        black:'#000000',
        boldGray:'#3F484B',
        lightBlue:'rgba(47, 112, 128, 0.05)',
        boldBlue:'#CBD2D9',
        errorColor:'#FF5449',
        gradientColor:'linear-gradient(179.44deg, rgba(216, 246, 255, 0.05) 0.48%, rgba(220, 245, 253, 0.63) 51.86%, rgba(220, 245, 253, 0.05) 92.33%);',
    },
}

export const state2 = {
    colors:{
        customBlue:'#4FC6DF',
        customBlack:'#061F24',
        customGray:'#4B6269',
        footerBackground:'#009FB8',
        white:'#FFFFFF',
        boldGray:'#3F484B',
        lightBlue:'rgba(47, 112, 128, 0.05)',
        boldBlue:'#CBD2D9',
        errorColor:'#FF5449',
        gradientColor:'linear-gradient(179.44deg, rgba(216, 246, 255, 0.05) 0.48%, rgba(220, 245, 253, 0.63) 51.86%, rgba(220, 245, 253, 0.05) 92.33%);',
    }
}