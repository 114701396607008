// TODO if will be 
// import "@fontsource/noto-sans-armenian"

import { useRoutes } from 'react-router-dom';
import Home from './pages/home/home';
import { Search } from './pages/search/search';
import i18n from "i18next";
import { Subscription } from './pages/subscription/subscription';
import { MainOutlet } from './outlets/mainOutlet';
import {en} from './translations/en'
import {am} from './translations/am'
import {fr} from './translations/fr'
import {ru} from './translations/ru'
import './App.css';
import { initReactI18next } from 'react-i18next';
import { Box } from '@mui/material';
import { NestedAnnouncement } from './pages/nestedAnnouncementPage/nestedAnnouncementPage';
import { NestedAboutUsPage } from './pages/nestedAboutUsPage';
import Staff from './pages/staff/staff';
import Announcement from './pages/posts/posts';
import PDFPage from './pages/pdfpage/pdfpage';
import Books from './pages/books/books'
i18n.use(initReactI18next).init({
  resources: {
    En: {
      translation: en,
    },
    Am: {
      translation: am,
    },
    Fr: {
      translation: fr,
    },
    Ru: {
      translation: ru,
    },
  },
  lng: localStorage.lang||"Am",
  fallbackLng: ["En","Ru","Am","Fr"],
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const routes = useRoutes([
    {
      path:'',
      element:<MainOutlet />,
      children:[
        {path:'',element:<Home/>},
        {path:'documents',element:<PDFPage/>},
        {path:'books',element:<Books/>},
        {path:':subMenuId', element: <Announcement/>},
        {path:'post/:postId', element: <NestedAnnouncement/>},
        {path:'staff',element:<Staff/>},
        {path:'staff/:id',element:<NestedAboutUsPage/>},
        {path:'search', element:<Search/>},
        {path:'subscribtion',element:<Subscription/>},
      ]
    }
  ])

  const style = {
    display: 'flex',
    flexDirection:'column',
    minHeight:'100vh',
  }

  return (
    <Box sx={style}>
      {routes}
    </Box>
  );
}

export default App;
