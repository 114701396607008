import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import i18next, { t } from "i18next";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "../../elements/button/button.js";
import { Flex, JustifyCenter } from "../../models/boxes.js";
import { ResultText } from "./../../customText";
import AnalysesCard from "./../analysesCard/analysesCard";
import CardComponent from "./../card/card";
import SpringCard from "./../springCard/springCard";
import { tabButton, tabContainer, tabDivder, tabList } from "./style.js";

function TabPanelComponent({
  searchData,
  isLoadSeeMore,
  setPageInfo,
  pageInfo,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(1);
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={tabContainer}>
      <TabContext value={value}>
        <Box sx={tabDivder}>
          <TabList sx={tabList} variant="scrollable" onChange={handleChange}>
            {
              searchData?.map(({ subMenu, foundPosts }, index) => {
                return foundPosts.length > 0 && <Tab sx={tabButton} label={subMenu[`title${i18next.language}`] + ` (${foundPosts.length})`} value={index + 1} key={subMenu.id} />;
              })
            }
          </TabList>
        </Box>
        <ResultText sx={{ mt: "32px" }}>
          {searchData.map(item => item.foundPosts.length).reduce((a, b) => a + b)} {t("searchResult")} «
          {searchParams.get("search")}» {t("byQuery")}
        </ResultText>
        {
          searchData?.map(({ subMenu, foundPosts }, index) => {
            return (
              <TabPanel sx={{ p: "0" }} value={index + 1}>
                <Flex gap={"8px"} sx={{ mt: "48px" }} flexWrap={"wrap"}>
                  {
                    foundPosts?.map(item => {
                      if (subMenu?.cardType?.card_type?.includes('Type 1')) {
                        return <SpringCard key={item.id} item={item} />
                      } else if (subMenu?.cardType?.card_type?.includes('Type 2')) {
                        return <AnalysesCard key={item.id} item={item} />;
                      } else if (subMenu?.cardType?.card_type?.includes('Type 3') || subMenu?.cardType?.card_type?.includes('Type 4') || subMenu?.cardType?.card_type?.includes('Type 5')) {
                        return <CardComponent key={item.id} item={item} />
                      }
                    })
                  }

                </Flex>
                <JustifyCenter>
                  <CustomButton
                    onClick={() => navigate(`/${subMenu.id}`)}
                    buttonStyle
                    text={t("seeMore")}

                  />
                </JustifyCenter>
              </TabPanel>
            )
          })
        }
      </TabContext>
    </Box>
  );
}

export default TabPanelComponent;
