import { state1 } from "../../colors"

export const accordionBlock = {
	background: '#009FB8',	
	color:state1.colors.white,
	boxShadow:'none',
	borderBottom:'2px solid #4FC6DF'
}

export const parentFooter = {
	maxWidth:'1440px',
	width:'100%',
	px:'5%'
}

export const expanedIcon = {
	transition:'0.3s',
}

export const iconsBox = {
	'& > svg': {
		marginRight:'16px',
		transition:'0.3s',
		cursor:'pointer',

		'&:hover': {
			transform:'scale(1.2)'
		}
	},
}

export const subscribe = {
	display:'flex',
	alignItems:'center',
	gap:'8px',
	cursor:'pointer'
}