import { Box, Divider, TextField } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/baseUrl";
import {
  Comment,
  CommentTitle,
  Commenter,
  Comments,
  ErrorMessage,
} from "../../customText";
import CustomButton from "./../../elements/button/button";
import CustomInput from "./../../elements/input/input";
import { FlexEnd, MainContainer } from "./../../models/boxes";
import {
  CommentPost,
  CommentPostLine,
  CustomTextArea,
  DividerLabel,
  InputStyle,
} from "./style";

function CommentForm() {
  const [comments, setComments] = useState(null);
  const [renderCount, setRenderCount] = useState(0);
  const { postId } = useParams();
  const [error, setError] = useState(false)
  const [state, setState] = useState({
    dataid: postId,
    email: "",
    comment: "",
    full_name: "",
  });
  useEffect(() => {
    (async () => {
      const {
        data: { docs },
      } = await Api.get(
        "/comments?where[or][0][and][0][dataid][equals]=" + postId
      );
      setComments(docs);
    })();
  }, [renderCount]);

  useEffect(()=>{
    const re = /\S+@\S+\.\S+/;
    return () => {
      for(const key in state){
        if(!state[key]){
          setError(true)
        }else{
          setError(false)
        }
      }

        if (!re.test(state.email)) {
            setError(true)
           
        } else {
            setError(false)
            
        }
    }
  },[state])

  const [errors, setErrors] = useState("");
  const send = async () => {
    const formData = new FormData();

    for (const key in state) {
      formData.append(key, state[key]);
    }

    try {
      await Api.post("comments", formData);
      setComments([...comments, {email:state.email, comment: state.comment, full_name:state.full_name, createdAt: new Date(Date.now())}])
      setState({ ...state, email: "", comment: "", full_name: "" });
      setRenderCount(renderCount + 1);
    } catch (error) {
      error?.response?.data?.errors &&
        setErrors(error.response.data.errors[0].message);
    }
  };
  return (
    <MainContainer>
      <CommentTitle pb={"24px"}>
        {t("leaveAComment")}
        <ErrorMessage>{errors}</ErrorMessage>
      </CommentTitle>
      <Box>
        <CustomInput
          value={state.full_name}
          onChange={(e) => setState({ ...state, full_name: e.target.value })}
          type="text"
          placeholder={t("nameSurname")}
          sx={InputStyle}
        />
        <Box sx={DividerLabel} />
        <CustomInput
          value={state.email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          type="email"
          placeholder={t("email")}
          sx={InputStyle}
        />

        <Box sx={DividerLabel} />
        <Box mb={"32px"} width="100%">
          <TextField
            value={state.comment}
            onChange={(e) => setState({ ...state, comment: e.target.value })}
            sx={CustomTextArea}
            fullWidth
            variant="outlined"
            minRows={2}
            placeholder={t("comment")}
            maxRows={Infinity}
            multiline
          />
        </Box>
        <FlexEnd sx={{ justifyContent: "end" }}>
          <CustomButton disabled={error} className="active" text={t('comment')} onClick={send} />
        </FlexEnd>
      </Box>
      <Divider sx={{ margin: "72px 0" }} />
      <Box sx={CommentPostLine}>
        <Comment>{t("comments")}</Comment>
        {comments?.map((item) => {
          return (
            <Box sx={CommentPost} key={item.id}>
              <Commenter>{item.full_name}</Commenter>
              <Comments>
                {new Date(item.createdAt).toLocaleDateString()}
              </Comments>
              <Comments>{item.comment}</Comments>
            </Box>
          );
        })}
      </Box>
    </MainContainer>
  );
}

export default CommentForm;
