import styled from "styled-components";
import { Swiper } from 'swiper/react';

export const StyledSliderAnalysis = styled(Swiper)(({
    "& .swiper-wrapper":{
        padding:'2px'
    },

    "& .swiper-pagination":{
        left:'50%',
        width:'fit-content',
        transform:'translate3d(-50%, 0, 0)',
    },

    "&.swiper":{
        margin: 0
    },
    
    "& .swiper-slide":{
        minWidth: 240,
    },
    
    "&.swiper-slide .MuiPaper-root":{
        boxShadow: 'none',
    },
}))
