import { state1 } from "../../colors"

export const container = {
    margin: '72px 0 48px 0',
    justifyContent:'space-between',

    '@media (max-width : 768px)': {
        flexDirection:'column',
        gap:'10px'
     }
}

export const currentDateStyle = {
    ml:'48px',
    '@media (max-width : 768px)': {
        ml:'0'
     } 
}

export const titleDate = {
    '@media (max-width : 768px)': {
        flexDirection:'column',
        alignItems:'center',
        gap:'10px'
     } 
}

export const icon = {
    color:state1.colors.black,
    transition:'0.3s',
    cursor:'pointer',
    '&:hover':{
        color:state1.colors.customBlue,
    }
}

export const iconSearch = {
    mr:'32px',
}

export const searchInput = {
    backgroundPosition: '5px center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '24px 24px',
	border: 'none',
	cursor: 'pointer',
	height: '40px',
	margin: '3px 0',
	padding: '0 0 0 34px',
	position: 'relative',
	transition: 'width 400ms ease, background 400ms ease',
	width:'0px',
    cursor: 'pointer',

    "&:focus": {
        border: '2px solid #c3c0ab',
        cursor: 'text',
        outline: 0,
        width: '250px',
        color:state1.colors.lightBlue,
        borderRadius:'8px',
    }
}

export const calendarStyle = {
    position:'absolute',
    left:'50%',
    transform:'translate(-100%)',
    zIndex:2,

    '@media (max-width : 768px)': {
        left:'50%',
        transform:'translate(-50%)',
    } 
}