import { Box } from "@mui/system";
import Slider from "react-slick";
import styled from "styled-components";
import { state1 } from "../../colors";

export const StyledSlider = styled(Slider)(({
    "& .slick-slider" :{
        maxWidth: '1440px',
        margin: '0 auto'
    },
    
    "& .slick-list":{
        maxHeight: '740px'
    },

    "& .slick-slide img":{
        width: '100%',
        height: '740px',
        objectFit: 'cover'
    
    },
    
    "& .slick-dots":{
        bottom: '30px'
    },
    
    "& .slick-dots li.slick-active button:before":{
        color: state1.colors.customBlue
    },
    
    "& .slick-dots li button:before":{
        fontSize: '12px',
        color: state1.colors.customBlue
    }
}))

export const buttonsContainer = {
    marginTop:'48px',
    gap:'16px',
    '@media (max-width : 428px)': {
        width:'100%',
        marginTop:'24px',
        flexDirection:'column',
        '& button':{
            width:'100%'
        }
    }
}

export const SliderContent = styled(Box)(({
    position: 'relative',
    "&>img":{
        maxHeight: '740px'
    }
})) 

