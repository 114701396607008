import React from 'react';
import { CustomButtonStyle, CustomButtonStyleGray } from './style';
import { Box } from '@mui/material';

function CustomButton ({text, buttonStyle, onClick, sx,className,disabled,customizeButton}) {
    if(!buttonStyle){
        return (
            <Box sx={sx}>
             <CustomButtonStyle
                 sx={customizeButton}
                 disabled={disabled} 
                 className={className}
                 onClick={onClick}
                 variant="outlined">{text} </CustomButtonStyle>
            </Box>
        )
    }else {
        return (
            <Box sx={sx}>
              <CustomButtonStyleGray
               sx={customizeButton} 
               className={className}
               onClick={onClick}>{text}</CustomButtonStyleGray>
            </Box>
        )
    }

}

export default CustomButton