import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Dialog, DialogContent } from "@mui/material";
import i18next, { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSubmenus } from "../../api/headerApis";
import { JustifyCenter } from "../../models/boxes";
import Loader from "../loader";
import {
  accordionBlock,
  iconsBox,
  parentFooter,
  subscribe
} from "./styles.js";

const Footer = ({ mainMenu, contact }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subMenus, setSubMenus] = useState(null);


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (id) => async (event, isExpanded) => {
    setIsLoading(true);
    setExpanded(isExpanded ? id : false);
    const data = await getSubmenus(id);
    setSubMenus(data);
    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <p>AMD 2050022721157001</p>
        </DialogContent>
      </Dialog>
      <JustifyCenter sx={accordionBlock}>
        <Box sx={parentFooter} component="div">
          {mainMenu?.map((page) => {
            return (
              <Accordion
                key={page.id}
                expanded={expanded === page.id}
                sx={accordionBlock}
                onChange={handleChange(page.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls={`panel${page.id}a-content`}
                  id={`panel${page.id}a-header`}
                >
                  <Typography variant="h5">
                    {page[`title${i18next.language}`]}
                  </Typography>
                </AccordionSummary>
                {isLoading ? (
                  <Loader />
                ) : (
                  subMenus?.map((subMenu) => {
                    return (
                      <AccordionDetails
                      key={subMenu.id}
                        onClick={() => navigate(subMenu.pageType==="dynamic"?subMenu.id:subMenu.pageType)}
                      >
                        <Typography sx={subscribe}>
                          {subMenu[`title${i18next.language}`]}
                          <ArrowRightAltIcon sx={{ fontSize: "40px" }} />
                        </Typography>
                      </AccordionDetails>
                    );
                  })
                )}
              </Accordion>
            );
          })}

          <Accordion sx={accordionBlock}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{t("contact")}</Typography>
            </AccordionSummary>
            {contact?.map((item) => {
              return (
                <AccordionDetails key={item.id}>
                  <Typography>{item[`state${i18next.language}`]}</Typography>
                  <Typography>{item[`address${i18next.language}`]}</Typography>
                  <Typography>
                    {t("phone")} {item.phone}
                  </Typography>
                </AccordionDetails>
              );
            })}
            <AccordionDetails>
              <Typography>{t("email")} info@dcp.am</Typography>
            </AccordionDetails>
            <AccordionDetails onClick={() => navigate("/subscribtion")}>
              <Typography sx={subscribe}>
                {t("subscribe")}
                <ArrowRightAltIcon sx={{ fontSize: "40px" }} />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <AccordionDetails>
            <Box sx={iconsBox}>
              <FacebookIcon
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/demconsolidation?mibextid=ZbWKwL",
                    "_blank"
                  )
                }
              />
              <TwitterIcon
                onClick={() =>
                  window.open("https://twitter.com/DCP_Armenia", "_blank")
                }
              />
              <InstagramIcon
                onClick={() =>
                  window.open(
                    "https://instagram.com/democraticconsolidation?igshid=MTIyMzRjYmRlZg==",
                    "_blank"
                  )
                }
              />
              <TelegramIcon
                onClick={() =>
                  window.open("https://t.me/demconsolidation", "_blank")
                }
              />
              <YouTubeIcon
                onClick={() =>
                  window.open(
                    "https://youtube.com/@democraticconsolidation",
                    "_blank"
                  )
                }
              />
            </Box>
          </AccordionDetails>
        </Box>
      </JustifyCenter>
    </>
  );
};

export default Footer;
