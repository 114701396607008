// import styled from 'styled-components';

import { state1 } from "../../colors";

export const programParent = {
  marginBottom: "72px",
  background:
    "linear-gradient(180deg, rgba(216, 246, 255, 0.05) 2.24%, rgba(220, 245, 253, 0.8) 51.43%, rgba(218, 246, 254, 0.05) 100%)",
};

export const programMain = {
  width: "100%",
  minHeight: "500px",
  margin: "0 auto 72px auto",
  maxWidth: "1440px",
  "@media (max-width: 1280px)": {
    minHeight: "auto",
  },
  ".programMain": {
    width: "100%",
    "@media (max-width: 1024px)": {
      justifyContent: "center",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
};

export const programMainText = {
  position:'absolute',
  left:'1%',
  width:'40%'
}

export const imageBlock = {
  position: "absolute",
  width: "440px",
  height: "100%",
  right: "0",
  top: "0",
  bottom: "0",
  borderRadius: "8px 0px 0px 8px",
  transition: "0.3s",
};

export const animationBlock = {
  position:"relative",
  right:"-100%",
  "@media (max-width: 1280px)": {
    display: "none",
  },
};

export const mobileProgramMain = {
  borderWidth: "1px",
  borderColor: state1.colors.customBlue,
  borderStyle: "solid",
  borderRadius: "16px",
  "@media (max-width: 1280px)": {
    width: "100%",
    paddingLeft: "12%",
    paddingRight: "12%",
    "@media (max-width: 768px)": {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },
};
