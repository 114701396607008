import { Api } from "./baseUrl";
import { queryGenerator } from "./search";

export const getMenus = async () => {
  const {
    data: { docs },
  } = await Api.get(
    "mainMenu?where[or][0][and][0][show][equals]=true&sort=index"
  );
  return docs;
};

export const getSubmenus = async (id) => {
  const params = {
    'where[or][0][and][0][show_in_header][equals]': 'true',
    'where[or][0][and][1][main_menu][equals]': id,
    sort: 'index'
  }
  const {
    data: { docs },
  } = await Api.get('subMenus', { params });
  return docs;
};

export const getSearchSubMenus = async () => {
  const {
    data: { docs },
  } = await Api.get('subMenus');
  return docs;
}

export const getSearchPosts = async(search, limit)=>{
  const params = {limit:20}
  const {
    data: { docs, hasNextPage },
  } = await Api.get(`posts?${search ? queryGenerator(search) : ''}&depth=1`, { params });
  return { docs, hasNextPage };
}

export const getSubmenu = async (id) => {
  const { data } = await Api.get(`subMenus/${id}?depth=1`);
  return data;
};

export const getPosts = async (mainPageSubMenuId, limit, start, end, search, signal) => {
  const params = {
    'where[or][0][and][0][status][equals]': 'published',
    'where[or][0][and][1][subMenus][equals]': mainPageSubMenuId,
    'sort':'-creation_date',
    limit: limit || 6,
  }
  start && (params['where[or][0][and][2][creation_date][greater_than]'] = start)
  end && (params['where[or][0][and][3][creation_date][less_than]'] = end)
  
  const {
    data: { docs, hasNextPage },
  } = await Api.get(`posts?${search ? queryGenerator(search) : ''}`, { params,signal });
  return { docs, hasNextPage };
};

export const getMainPageSubMenus = async () => {
  const params = {
    'where[or][0][and][0][show_in_main_page][equals]': 'true',
    sort: 'index',
    depth: '1'
  }
  const {
    data: { docs },
  } = await Api.get('subMenus', { params });
  return docs;
};
