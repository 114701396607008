import { state1 } from '../../colors';

export const imagesBlock = {
    backgroundColor:state1.colors.boldGray,
    padding:'4% 5%',
    marginBottom:'72px',
}

export const imagesContainer = {
    display: 'grid',
    gridTemplateColumns: '0.9fr 0.20fr 0.1fr 0.13fr 0.21fr',
    gridAutoRows: '214px',
    gap: '26px 32px',
    maxWidth:'1440px',
    margin:'0 auto',
    '@media (max-width : 1280px)': {
         gridAutoRows: '150px',
    },
    '@media (max-width : 768px)': {
        gridAutoRows: '100px',
   }
}

export const imageContent = {
    borderRadius:'8px',
    '&:nth-of-type(1)':{
        gridColumn: 'span 1',
        gridRow: 'span 2'
    },
    '&:nth-of-type(2)':{
        gridColumn: 'span 3',
    },
    '&:nth-of-type(3)':{
        gridColumn: 'span 1'
    },
    '&:nth-of-type(4)':{
        gridColumn: 'span 2'
    },
    '&:nth-of-type(5)':{
        gridColumn: 'span 2'
    },
    '@media (max-width : 1280px)': {
        '&:nth-of-type(4), &:nth-of-type(2)':{
            gridColumn: 'span 4'
        },
        '&:nth-of-type(3),&:nth-of-type(5)':{
            display: 'none'
        },
     },
     '@media (max-width : 768px)': {
        '&:nth-of-type(1)':{
            gridColumn: 'span 5'
        },
        '&:nth-of-type(4), &:nth-of-type(2)':{
            display: 'none'
        },
     },
}