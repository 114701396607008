import { Box, SvgIcon } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/baseUrl";
import { ReactComponent as Apostrophe } from "../../assets/images/appostrophe.svg";
import { RenderOutput } from "../../utils/editorOutputGenerator";
import {
  PeopleBiographyName,
  PeopleBiographyPosition
} from "./../../customText";
import { Flex } from "./../../models/boxes";
import {
  apostropheBottom,
  apostropheTop,
  peopleBlockContext,
  personBlock,
  personBlockImg,
  personBlockImgContent,
} from "./style";
import placeholder from '../../assets/person-placeholder.png'
import { Helmet } from "react-helmet";
function AboutPerson() {
  const [person, setPerson] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      const { data } = await Api.get(`/team/${id}`);
      setPerson(data);
    })();
  }, [id]);

  return (
    <Flex sx={personBlock}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{person?.[`full_name${i18next.language}`]}</title>
      </Helmet>
      <Box sx={personBlockImg}>
        <Box
          sx={personBlockImgContent}
          component="img"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = placeholder;
          }}
          src={person?.url} />
      </Box>
      <Box sx={peopleBlockContext}>
        <PeopleBiographyName>
          {person?.[`full_name${i18next.language}`]}
        </PeopleBiographyName>
        <PeopleBiographyPosition>
          {person?.[`position${i18next.language}`]}
        </PeopleBiographyPosition>
        <Flex>
          <SvgIcon
            sx={apostropheTop}
            component={Apostrophe}
            viewBox="0 0 34 34"
          ></SvgIcon>
          <Box pl={2}>
            <RenderOutput initialValue={person?.[`biography${i18next.language}`]} />
          </Box>
          <SvgIcon
            sx={apostropheBottom}
            component={Apostrophe}
            viewBox="0 0 34 34"
          ></SvgIcon>
        </Flex>
      </Box>
    </Flex>
  );
}

export default AboutPerson;
