import { MainContainer, FlexWrap } from "../../models/boxes";
import { NestedPeopleTitle } from "../../customText";
import People from "../../components/people/people";
import { AboutPeople, AboutPeopleBox } from "../../components/nestedAboutUsComponent/style";
import { useEffect, useState } from "react";
import { Api } from "../../api/baseUrl";
import i18next, { t } from "i18next";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

const Staff = () => {
    const [members, setMembers] = useState(null)

    useEffect(() => {
        (async () => {
            const { data: { docs } } = await Api.get('team')
            setMembers(docs.sort((a, b) => a.index - b.index))
        })()
    }, [])
    return (
        <MainContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('team')}</title>
            </Helmet>
            <Box sx={AboutPeople}>
                <NestedPeopleTitle>{t('team')}</NestedPeopleTitle>
                <FlexWrap className="about-people-box" sx={AboutPeopleBox}>
                    {members?.map((elem) => {
                        return (
                            <People
                                key={elem.id}
                                url={elem.url}
                                name={elem[`full_name${i18next.language}`]}
                                position={elem[`position${i18next.language}`]}
                                id={elem.id}
                            />
                        )
                    })}
                </FlexWrap>
            </Box>
        </MainContainer>
    )
}

export default Staff