import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import { Box } from '@mui/material';
import { getMenus } from "../api/headerApis";
import { Api } from "../api/baseUrl";

export const MainOutlet = () =>{
    const [mainMenu, setMainMenu] = useState(null)
    const [contact, setContact] = useState(null)
    useEffect(() => {
        (async () => {
    
          const {
            data: { docs },
          } = await Api.get("contact");
          const menus = await getMenus()
          setMainMenu(menus)
          setContact(docs);
    
        })();
      }, []);
    useEffect(()=>{
        window.scrollTo(0, 0)
    } )
    return(
        <>
            <Header mainMenu={mainMenu} contact={contact}/>
            <Box sx={{flex:1}}>
                 <Outlet/>
            </Box>
            <Footer mainMenu={mainMenu} contact={contact}/>
        </>    
    )
}