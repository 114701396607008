import React, { useRef, useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import useWindowResize from "../../hooks/useWindowResize";
import ReactPlayer from "react-player/lazy";
import { reactPlayer } from "./style";

function VideoPlayer({ url }) {
  const ref = useRef();
  console.log(url);
  const { windowWidth } = useWindowResize();
  const { matches } = window.matchMedia("(orientation: portrait)");
  const [playerDimensions, setPlayerDimensions] = useState({
    width: "100%",
    height: "100%",
  });
  const [forceRenderKey, setForceRenderKey] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true)
  const handleTogglePlay = () => {
    setIsPlaying(prevIsPlaying => !prevIsPlaying);
  };
  useEffect(() => {
    const updatePlayerSize = () => {
      const newWidth = !matches ? "100%" : `${windowWidth}px`;
      const newHeight = `${parseInt((windowWidth * 56.25) / 100)}px`;

      setPlayerDimensions({
        width: newWidth,
        height: newHeight,
      });

      setForceRenderKey(prevKey => prevKey + 1);
    };
    
    updatePlayerSize();
    window.addEventListener("orientationchange", updatePlayerSize);
    return () => {
      window.removeEventListener("orientationchange", updatePlayerSize);
    };
  }, [matches, windowWidth]);
 
 
  const handleKeyPress = (event) => {
    if (event.code === "Space") {
      event.preventDefault()
      handleTogglePlay();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleTogglePlay]);

  useEffect(()=>{
    if(ref && ref.current && ref.current instanceof HTMLElement){
      ref?.current?.addEventListener("keydown", handleKeyPress)
    }

    return () => {
      if(ref && ref.current && ref.current instanceof HTMLElement){
        ref.current.removeEventListener("keydown", handleKeyPress);
      }
    };
  },[handleTogglePlay])

  return (
    <Box key={forceRenderKey} style={{ width: playerDimensions.width, height: playerDimensions.height }}>
      <ReactPlayer
        ref={ref}
        
        key={forceRenderKey}
        className="react-player"
        width={playerDimensions.width}
        height={playerDimensions.height}
        controls={true}
        muted={(url?.includes('fb')||url?.includes('facebook'))?true:false}
        url={url?.includes('https://www.facebook.com/reel/')?`https://www.facebook.com/dcp/videos/${url?.split('https://www.facebook.com/reel/')?.[1]}`:url}
        previewTabIndex={0}
        playing={isPlaying}
        config={{
          facebook: {
            playerId:url,
            attributes: {
              "data-width": windowWidth + "px",
              "data-height": parseInt((windowWidth * 56.25) / 100) + "px",
            },
          },
        }}
      />
    </Box>
  );
}

export default VideoPlayer;


