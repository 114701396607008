import { Divider, SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import { CustomOpenedMenuLink } from "../../../customText";
import { JustifyCenter, SpaceBetween } from "../../../models/boxes";
import {
  apostropheBottom,
  apostropheTop,
  openedMenuBlock,
  menuText,
  menuContent,
} from "../style";
import { ReactComponent as Apostrophe } from "../../../assets/images/appostrophe.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { DefineComponent } from "../../defineComponent/defineComponent";

export const SubMenus = ({ subMenus, handleMouseOut }) => {
  const [hovered, setHovered] = useState(null);
  const [showPreview, setShowPreview] = useState(null);
  const [haveSubText, setHaveSubText] = useState(false);
  let timeoutId
  const navigate = useNavigate();
  useEffect(() => {
    setShowPreview(null);
    if (subMenus?.[0]?.custom_text) {
      setShowPreview({
        subTexts: {
          titleAm: subMenus[0].subTitleAm,
          titleEn: subMenus[0].subTitleEn,
          titleRu: subMenus[0].subTitleRu,
          titleFr: subMenus[0].subTitleFr,
        },
      });
      setHaveSubText(true);
      setHovered(subMenus[0].id);
    }

    if (!subMenus?.[0]?.custom_text) {
      setHaveSubText(false);
      setHovered(subMenus?.[0]?.id);
      setShowPreview(subMenus?.[0]);
    }
  }, []);

  const onMouseHoverSubMenu = (subMenu) => {
    timeoutId = setTimeout(() => {
      setShowPreview(null);
      setHovered(subMenu.id);
      if (subMenu.custom_text) {
        setHaveSubText(true);
        setShowPreview({
          subTexts: {
            titleAm: subMenu.subTitleAm,
            titleEn: subMenu.subTitleEn,
            titleRu: subMenu.subTitleRu,
            titleFr: subMenu.subTitleFr,
          },
        });
      } else {
        setShowPreview(subMenu);
        setHaveSubText(false);
      }
    }, 200)
  };

  const outOfHover = () => {
    clearTimeout(timeoutId)
  }

  const clickToNavigate = (item) => {
    navigate(item.pageType === "dynamic" ? item.id : item.pageType);
    handleMouseOut();
  };

  return (
    <>
      <SpaceBetween className="opened-menu-block" sx={openedMenuBlock}>
        <Box sx={menuText}>
          {subMenus?.map((item) => {
            return (
              <CustomOpenedMenuLink
                variant="caption"
                key={item.id}
                className={item.id === hovered && "active"}
                onMouseOver={() => onMouseHoverSubMenu(item)}
                onMouseLeave={outOfHover}
                onClick={() => clickToNavigate(item)}
              >
                <br />{" "}
                {item?.[`title${i18next.language}`]?.split("<br/>")?.join("\n")}
              </CustomOpenedMenuLink>
            );
          })}
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <JustifyCenter sx={menuContent} p={2}>
          {haveSubText && (
            <SvgIcon
              sx={apostropheTop}
              component={Apostrophe}
              viewBox="0 0 34 34"
            ></SvgIcon>
          )}
          <DefineComponent incomingComponent={showPreview} />
          {haveSubText && (
            <SvgIcon
              sx={apostropheBottom}
              component={Apostrophe}
              viewBox="0 0 34 34"
            ></SvgIcon>
          )}
        </JustifyCenter>
      </SpaceBetween>
    </>
  );
};
