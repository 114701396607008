import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import i18next from "i18next";
import { Link } from "react-router-dom";
import {
  CustomCardDate,
  CustomCardName,
  CustomCardText,
  CustomSpringSubTitle,
} from "../../customText";
import { RenderOutput } from "../../utils/editorOutputGenerator";
import { useParams } from "react-router-dom";
import {
  CustomCard,
  CustomCardContent,
  CustomCardContentImg,
  CustomCardImage,
  CustomCardElipsies,
} from "./style";
import placeholder from "../../assets/person-placeholder.png";
import dayjs from "dayjs";

export default function AnalysesCard({ item }) {
  const { subMenuId } = useParams();
  return (
    // ?title=${item?.[`title${i18next.language}`]?.split(" ").join("-")}
    <Link to={`/post/${item?.id}?title=${item?.[`title${i18next.language}`]?.split(" ").join("-")}`}>
    <Card
      className="slider-mui-analysis"
      sx={CustomCard}
    >
      <CardActionArea>
        <CardContent sx={CustomCardContentImg}>
          <CardMedia
            className="custom-card-image"
            sx={CustomCardImage}
            component="img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholder;
            }}
            height="224"
            image={item?.additional_photo?.url||item?.url}
          />
        </CardContent>
        <CardContent className="custom-card-content" sx={CustomCardContent}>
          {!!subMenuId ? (
            <CustomCardName>
              {item?.[`title${i18next.language}`]}
            </CustomCardName>
          ) : (
            <CustomCardName className="custom-card-name">
              {item?.[`title${i18next.language}`]}
            </CustomCardName>
          )}
          <CustomCardDate className="custom-card-date">
            {dayjs(item?.creation_date).format('YYYY-MM-DD')}
          </CustomCardDate>
          <CustomCardText className="custom-card-text">
            <RenderOutput
              initialValue={item?.[`description${i18next.language}`]}
            />
          </CustomCardText>
        </CardContent>
      </CardActionArea>
    </Card>
    </Link>
  );
}
