import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { PDFComponentTitle, PDFComponentSubTitle } from "../../customText";
import { PDFCard, PDFCardMedia, PDFButton } from "./style";
import DNA from "../../assets/images/dna.png";
import { Button } from "@mui/material";
import { Api } from "../../api/baseUrl";
import i18next from "i18next";
import { t } from "i18next";
import { state1 } from "../../colors";
import { Link } from "react-router-dom";

function PDFComponent({type}) {
  const [documents, setDocuments] = React.useState(null)
  React.useEffect(()=>{
    (async()=>{
      const {data:{docs}} = await Api.get(type||'documents')
      setDocuments(docs)
    })()
  },[])
  console.log(type);
  return (
    <>
      {
        documents?.map(item=>{
          return(
            <Card className="pdf-card" sx={PDFCard}>
            <CardMedia className="pdf-card-media" sx={PDFCardMedia} image={item.url} />
            <CardContent>
              <PDFComponentTitle gutterBottom variant="h5" component="div">
              {item?.[`title${i18next.language}`]}
              </PDFComponentTitle>
              <PDFComponentSubTitle onClick={()=> window.open(item?.[`link${i18next.language}`])} sx={PDFButton} component="div">
                  {t('download')}
              </PDFComponentSubTitle>
            </CardContent>
          </Card>
          )
        })
      }
    </>
  );
}

export default PDFComponent;
