import { state1 } from "../../colors"

export const CustomCard = {
    borderRadius:'8px',
    maxWidth:'306px',
    boxShadow:'none',
    cursor:'pointer',

    "& > div": {
        borderRadius:"8px",
    },
    
    "&:hover .custom-card-image": {
        transition:'.3s',
        transform:'scale(1.2)',
    },

    "&:hover .custom-card-date, &:hover .custom-card-text": {
        transition:'.3s',
        color:state1.colors.customBlue
    },

    "& .MuiBox-root" : {
        overflow:"hidden",
    },
}

export const CustomCardImage = {
    borderRadius:'8px',
    transition:'.3s',
}

export const playBtn = {
    position:'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%, -50%)',
    zIndex:1
}