export const am = {
  about: "Մեր մասին",
  party: "գործունեություն",
  projects: "Նախագծեր",
  information: "Տեղեկատվություն",
  contact: "Կապ",
  download:"Ներբեռնել",
  subscribe: "Բաժանորդագրվել",
  donate: "Նվիրաբերել",
  seeMore: "Տեսնել ավելին",
  joinUs: "Միանալ մեզ",
  program: "Ծրագիր",
  internationalRelations: "Միջազգային հարթություն",
  politicalScience: "Քաղաքակրթություն",
  militaryScience: "Ռազմավարական գիտություն",
  politicalEconomy: "Քաղաքական տնտեսական հարթություն",
  socioPolitical: "Սոցիալ-տնտեսական հարթություն",
  artsakhIssue: "Արցախյան հիմնախնդիր",
  diaspora: "Սփյուռք",
  politicsProgramm: "Կուսակցության ծրագիր",
  gallery: "Տեսադարան",
  news: "Նորություններ",
  publications: "Հրապարակումներ",
  analysis: "Վերլուծություններ",
  team: "Մեր թիմը",
  read: "Ընթերցել",
  ideology: "Գաղափարախոսություն",
  charter: "կանոնադրություն",
  email: "Էլ. փոստ.՝",
  search: "Որոնում",
  phone: "Հեռ.",
  leaveAComment: "Թողնել մեկնաբանություն",
  nameSurname: "Անուն Ազգանուն",
  comment: "Մեկնաբանություն",
  comments: "Մեկնաբանություններ",
  moreInfo: "Այստեղ կարող եք գտնել հավելյալ տեղեկատվություն",
  searchResult: "որոնման արդյունք",
  byQuery: "հարցումով",
  print: "Տպել",
  linkCopied: "Հղումը պատճենված է",
  copyLink: "Պատճենել հղումը",
  pdf: "PDF",
  subscribtion: "Բաժանորդագրություն",
  letsGetInTouch: "Եկեք կապ հաստատենք",
  pdfDocument: 'PDF փաստաթուղթ',
  pdfBooks: 'PDF գրքեր',

  // SEO
  
  Home: "Գլխավոր - Ժողովրդավարական Համախմբում",
};
