import { SwiperSlide } from "swiper/react";
import AnalysesCard from "../analysesCard/analysesCard";
import CardComponent from "../card/card";
import Program from "../program/program";
import { StyledSlider } from "../sliderMui/style";
import { StyledSliderAnalysis } from "../sliderMuiAnalysis/style";
import SpringCarousel from "../springCarousel/springCarousel";

export const DefineHomePageComponent = ({ subMenu, posts }) => {
  const generateComponent = () => {
    if (subMenu?.cardType?.card_type?.includes("Type 1")) {
      return <SpringCarousel posts={posts} />;
    } else if (subMenu?.cardType?.card_type?.includes("Type 2")) {
      return (
        <StyledSliderAnalysis
          className="analysis-component"
          slidesPerView="auto"
          spaceBetween={24}
          breakpoints={{
            375: {
              slidesPerView: 1.365,
            },
            600: {
              slidesPerView: 2.184,
            },

            768: {
              slidesPerView: 2.795,
            },

            814: {
              slidesPerView: 2.96296,
            },

            1024: {
              slidesPerView: 3.72736,
            },

            1280: {
              slidesPerView: 4.6592,
            },

            1440: {
              slidesPerView: 5.2416,
            },
          }}
        >
          {posts?.map((item, i) => {
            return (
              <SwiperSlide key={item.id}>
                <AnalysesCard item={item} />
              </SwiperSlide>
            );
          })}
        </StyledSliderAnalysis>
      );
    } else if (subMenu?.cardType?.card_type?.includes("Type 3")) {
      return (
        <StyledSlider
          slidesPerView={1}
          spaceBetween={24}
          breakpoints={{
            375: {
              slidesPerView: 1.05,
            },
            600: {
              slidesPerView: 1.68,
            },

            768: {
              slidesPerView: 2.1504,
            },

            814: {
              slidesPerView: 2.2792,
            },

            1024: {
              slidesPerView: 2.8672,
            },

            1280: {
              slidesPerView: 3.584,
            },

            1440: {
              slidesPerView: 4.032,
            },
          }}
        >
          {posts?.map((item, i) => {
            return (
              <SwiperSlide key={item.id}>
                <CardComponent item={item} />
              </SwiperSlide>
            );
          })}
        </StyledSlider>
      );
    } else if (subMenu?.cardType?.card_type?.includes("Type 4")) {
      return <Program state={posts}/>;
    }

    return <></>;
  };
  return generateComponent();
};
