import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { JustifyCenter, MainContainer } from "../../models/boxes";

import Masonry from "@mui/lab/Masonry";

import SpringCard from "../../components/springCard/springCard";
// import Loader from "../../components/loader";
import i18next, { t } from "i18next";
import { useParams } from "react-router-dom";
import { getPosts, getSubmenu } from "../../api/headerApis";
import AnalysesCard from "../../components/analysesCard/analysesCard";
import CardComponent from "../../components/card/card";
import Loader from "../../components/loader";
import { TitleSearchDate } from "../../components/titleSearchDate/titleSearchDate";
import CustomButton from "../../elements/button/button";
import { Helmet } from "react-helmet";
import { masonryWrapperIn } from './style';

const Announcement = () => {
  const { subMenuId } = useParams();
  const [info, setInfo] = useState(null);
  const [posts, setPosts] = useState(null);
  const [allDates, setAllDates] = useState({ start: "", end: "" });
  const [search, setSearch] = useState("");
  const [pageInfo, setPageInfo] = useState({ limit: 10, hasNextPage: true });
  const [isLoadSeeMore, setIsLoadSeeMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isInitialRender = useRef(true);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const subMenuInfo = await getSubmenu(subMenuId);
      const { docs, hasNextPage } = await getPosts(
        subMenuId,
        pageInfo.limit,
        allDates.start,
        allDates.end,
        search
      );
      setInfo(subMenuInfo);
      setPosts(docs);
      setIsLoading(true);
      setPageInfo({ ...pageInfo, hasNextPage: hasNextPage });
      setIsLoadSeeMore(false);
    })();
  }, [allDates, pageInfo.limit, search, subMenuId]);

  const card_types = ["Type 3", "Type 4", "Type 5"];
  return (
    <Box sx={masonryWrapperIn} className="masonry-wrapper-in">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{info?.[`title${i18next.language}`]}</title>
      </Helmet>
      <MainContainer>
        {info && (
          <TitleSearchDate
            currentTitle={info[`title${i18next.language}`]}
            onChangeSearchValue={setSearch}
            searchValue={search}
            setDates={setAllDates}
            currentDate={`${allDates.start.split("T")[0]} ${
              allDates.end.split("T")[0]
            }`}
          />
        )}
        <Masonry
          sx={{ alignContent: "center" }}
          columns={{ lg: 4, md: 3, sm: 2, xs: 1 }}
          spacing={2}
        >
          {posts?.map((item) => {
            if (info?.cardType?.card_type?.includes("Type 1")) {
              return <SpringCard key={item.id} item={item} />;
            } else if (info?.cardType?.card_type?.includes("Type 2")) {
              return <AnalysesCard key={item.id} item={item} />;
            } else if (
              card_types.some((item) =>
                info?.cardType?.card_type?.includes(item)
              )
            ) {
              return <CardComponent key={item.id} item={item} />;
            }
          })}
        </Masonry>
        {pageInfo.hasNextPage && (
          <JustifyCenter sx={{ mt: "16px" }}>
            {isLoadSeeMore ? (
              <Loader />
            ) : (
              <CustomButton
                onClick={() =>
                  setPageInfo({ ...pageInfo, limit: 2 * pageInfo.limit })
                }
                buttonStyle
                text={t("seeMore")}
              />
            )}
          </JustifyCenter>
        )}
      </MainContainer>
    </Box>
  );
};

export default Announcement;
