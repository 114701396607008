import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Box } from "@mui/system";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../api/baseUrl.js";
import People from "../people/people.js";
import { FlexColumn, FlexWrap, JustifyCenter } from "./../../models/boxes";
import {
  aboutUsBox,
  aboutUsBoxFooter,
  aboutUsBoxFooterRemove,
  aboutUsComponent,
  arrowDownIcon,
} from "./styles";
function AboutUs() {
  const [footer, setFooter] = useState(false);
  const [members, setMembers] = useState(null);
  useEffect(() => {
    (async () => {
      const {
        data: { docs },
      } = await Api.get("/team?limit=10&sort=index");
      setMembers(docs);
    })();
  }, []);

  return (
    <Box mb="72px" position="relative">
      <FlexColumn
        className="about-us-box"
        sx={aboutUsBox}
        onMouseOver={() => setFooter(true)}
        onMouseOut={() => setFooter(false)}
      >
        <FlexWrap className="about-us-component" sx={aboutUsComponent}>
          {members?.map((elem) => {
            return (
              <People
                key={elem.id}
                url={elem.url}
                name={elem[`full_name${i18next.language}`]}
                position={elem[`position${i18next.language}`]}
                id={elem.id}
              />
            );
          })}
        </FlexWrap>
        <Link to="/staff">
          <JustifyCenter
            sx={footer ? aboutUsBoxFooter : aboutUsBoxFooterRemove}
          >
            <KeyboardDoubleArrowDownIcon sx={arrowDownIcon} />
          </JustifyCenter>
        </Link>
      </FlexColumn>
    </Box>
  );
}

export default AboutUs;
