export const personBlock = {
  maxWidth: "1200px",
  margin: "auto",
  padding: "4.5%",
  gap: "20px",
  "@media (max-width:991px)": {
    flexDirection: "column",
    alignItems: "center",
  },
};

export const peopleBlockContext = {
  width: "100%",
};

export const personBlockImg = {
  maxWidth: "400px",
};

export const personBlockImgContent = {
  width: "100%",
  aspectRatio: "3/4",
  objectFit: "cover",
  borderRadius: "10px",
};

export const apostropheBottom = {
  alignSelf: "end",
  fontSize: "32px",
  transform: "rotate(180deg)",
};

export const apostropheTop = {
  fontSize: "32px",
};
