import {state1, state2} from '../../colors';

export const icon = {
    color:state1.colors.customGray,
    transition:'0.2s',
    cursor:'pointer',
    '&:hover':{
        color:state1.colors.customBlue,
        textDecoration:'underline',
    }
}

