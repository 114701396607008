import { state1 } from "../../colors";

export const appBarHeader = {
  top: 0,
  maxHeight: "800px",
  background: state1.colors.white,
  zIndex: 17,

  ".opened-search-menu": {
    maxHeight: "600px",
    boxShadow: "0 0px 1px 0px",
  },

  ".MuiButtonBase-root" : {
    minWidth:"60px"
  }
};

export const headerLink = {
  width: "100%",
  justifyContent: "flex-end",
  px: { md: "12px", lg: "54px" },
};

export const logoBlock = {
  display: { xs: "none", md: "flex" },
};

export const menuResponsiveButtons = {
  margin: "50px 16px",
  "& button": {
    width: "100%",
    marginBottom: "24px",
  },
};

export const burgerButton = {
  display: { xs: "block", md: "none" },
  "&.menu-opened-responsive": {
    "& .MuiPaper-root": {
      left: "0 !important",
      right: "0",
      maxWidth: "100%",
    },
  },
};

export const respLogoBlock = {
  display: { xs: "flex", md: "none" },
  flexGrow: 1,
};

export const respHeaderBlock = {
  flexGrow: 1,
  display: { xs: "none", md: "flex" },
};

export const respHeaderLink = {
  display: "block",
  px: 1.5,
  my: 2,
  color: state1.colors.white,
};

export const menuIconBlock = {
  flexGrow: 1,
  display: { xs: "flex", md: "none" },
};

export const icon = {
  color: state1.colors.customGray,
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    color: state1.colors.customBlue,
  },
};

export const burgerBtnColor = {
  color: state1.colors.customGray,
};

export const popupExpanded = {
  padding: 0,
  maxHeight: 0,
  opacity: 0,
  visibility: "hidden",

  "&.search-menu": {
    position: "sticky",
    top: "64px",
    left: 0,
    width: "100%",
    maxHeight: "600px",
    padding: "36px 0",
    backgroundColor: state1.colors.white,
    boxShadow: "0 0px 1px 0px",
    transition: ".3s",
    zIndex: 9,
    visibility: "visible",
    opacity: 1,
    overflow: "hidden",
  },
};

export const popupIcon = {
  color: state1.colors.customBlack,
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": {
    color: state1.colors.customBlue,
  },
};
export const popupIconInput = {
  position: "absolute",
  right: "18px",
  top: "0",
  paddingRight: "5%",
};

export const popupButtonStyle = {
  backgroundColor: state1.colors.black,
  borderRadius: "8px",
  width: "100%",
  marginBottom: "2%",
};

// openedSearch Hover menu
export const openedMenu = {
  position: "absolute",
  top: "100%",
  left: "0",
  width: "100%",
  maxHeight: "0",
  height: "auto",
  color: state1.colors.customBlack,
  transform: "translate(0, 0%)",
  transition: "0.6s",
  overflow: "hidden",
  zIndex: -1,
  backgroundColor: state1.colors.white,
};

export const openedMenuBlock = {
  maxWidth: "966px",
  margin: "2.5% auto",
  padding: "0 5%",
};

export const menuText = {
  flex: "0 0 50%",
  padding:"16px",
  justifyContent:"center"
}

export const menuContent = {
  flex: "0 0 50%"
}

export const apostropheBottom = {
  alignSelf: "end",
  fontSize: "32px",
  transform: "rotate(180deg)",
};

export const apostropheTop = {
  fontSize: "32px",
};

export const projectImage = {
  width: "67%",
  maxHeight: "404px",
};

export const informationImage = {
  width: "18%",
  marginRight: "4%",
  "&>img": {
    maxHeight: "86px",
    borderRadius: "8px",
  },
};

export const address = {
  maxWidth: "500px",
  width: "100%",
};

export const addressImage = {
  position: "relative",
  width: "100%",
  cursor: "pointer",

  "@media (max-width : 768px)": {
    display: "none",
  },
};

export const accordionBlock = {
  width: "100%",
};

export const addressImageChild = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
  backgroundColor: "rgba(6, 31, 36, 0.2)",
  backdropFilter: "blur(4px)",
  borderRadius: "8px",
  overflow: "hidden",

  "& ~ img": {
    maxHeight: "300px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  },
};

export const subscribeBtn = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  alignItems: "center",
  fontSize: "24px",
  gap: "8px",
  transform: "translate(-50%, -50%)",
  color: state1.colors.white,
};
