import { state1 } from "../../colors";

export const PDFCard = {
    width:'320px',
    textAlign:'center',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius:'8px',
    backgroundColor:'#f8f8f8'
}

export const PDFCardMedia = {
    height:'200px'
}

export const PDFButton = {
    color:state1.colors.customBlue,
    transition:'transform 0.3s',
    cursor:'pointer',
    '&:hover' : {
       transform:'scale(1.2)',
    }
}