import chevronLeft from "../../assets/images/chevron-left.svg";
import chevronRight from "../../assets/images/chevron-right.svg";

export const container = {
  width: "20%",
  height: "292px",
  margin: "0 auto",
};

export const springCarousel = {
  maxWidth: "600px",
  height: "300px",
  margin: "0 auto",
  opacity: 1,
  "@media (max-width : 428px)": {
    maxWidth: "400px",
  },

  "& .css-1qzevvg": {
    top: "-50%",
    width: "100%",
    margin: "0",
    transform: "translateY(-50%)",
  },

  "& .css-1qzevvg img":{
    cursor:"pointer",
    "&:first-of-type": {
        content:`url(${chevronLeft})`,
    },

    "&:last-of-type": {
        content:`url(${chevronRight})`,
    },
  }

};

export const apostrophe = {
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%)",
  fontSize: "54px",
  "@media (max-width : 993px)": {
    display: "none",
  },
};
