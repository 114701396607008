import React from "react";
import LinkIcon from "@mui/icons-material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconText from "../../elements/iconText/iconText";
import { FlexEnd } from "../../models/boxes";
import { t } from "i18next";
import { Button } from "@mui/material";
import ReactToPrint from "react-to-print";

function DocumentIconsGroup({ componentRef }) {
  return (
    <FlexEnd gap={3}>
      <Button
        onClick={() => navigator.clipboard.writeText(window.location.href)}
      >
        <IconText
          titleTooltip={t("linkCopied")}
          IconName={LinkIcon}
          iconText={t("copyLink")}
        />
      </Button>

      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => (
          <Button>
            <IconText IconName={PictureAsPdfIcon} iconText={t("pdf")} />
          </Button>
        )}
      />
    </FlexEnd>
  );
}

export default DocumentIconsGroup;
