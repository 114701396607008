import React from "react";
import AboutPerson from './../aboutPerson/index';

function NestedAboutUsComponent() {
  return (
    <AboutPerson />
  );
}

export default NestedAboutUsComponent;
