import styled from '@emotion/styled'
import { Button } from '@mui/material'
import {state1} from '../../colors'

export const CustomButtonStyle = styled(Button)({
    padding:'18px 23px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform:'capitalize',
    borderWidth: '1px',
    borderColor:state1.colors.customBlue,
    borderRadius: '8px',
    color:state1.colors.customBlue,
    backgroundColor: 'rgba(79, 198, 223, 0.05)',
    backdropFilter:'blur(8px)',
    '&:hover':{
        color:state1.colors.white,
        borderColor:state1.colors.customBlue,
        background: state1.colors.customBlue,
        backdropFilter:'blur(0px)',
    },
    '&:active':{
        color:state1.colors.white,
        borderColor:state1.colors.customBlue,
        background: state1.colors.customBlue,
    },
    '&:disabled':{
        opacity:0.45,
    },
    '@media (max-width : 768px)': {
        color:state1.colors.white,
        borderColor:state1.colors.customBlue,
        background: state1.colors.customBlue,
        backdropFilter:'blur(0px)',
     },
})

export const CustomButtonStyleGray = styled(Button)({
    padding:'18px 23px',
    fontWght: '700',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform:'capitalize',
    borderWidth: '1px',
    borderColor:state1.colors.customGray,
    borderRadius: '8px',
    borderStyle:'solid',
    color: state1.colors.customGray,
    '&:hover':{
        color:state1.colors.white,
        borderColor:state1.colors.customGray,
        background: state1.colors.customGray,
    }
})