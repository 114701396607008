import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

const useWindowResize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const isPortrait = useMediaQuery("(max-width:991px)");

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isPortrait]);

  return {windowWidth, windowHeight}
};

export default useWindowResize;
