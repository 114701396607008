//TODO vidio and image component

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import i18next from "i18next";
import { React } from "react";
import { Link } from "react-router-dom";
import { CustomCardDate, CustomCardText } from "../../customText";
import { CustomCard, CustomCardImage } from "./style";
import placeholder from '../../assets/photo-placeholder.png';
import dayjs from "dayjs";

function CardComponent({ item }) {
  return (
    <Link to={`/post/${item.id}?title=${item?.[`title${i18next.language}`]?.split(" ").join("-")}`}>
    <Card className="custom-card" sx={CustomCard}>
      <Box position="relative">
        <CardMedia
          className="custom-card-image"
          sx={CustomCardImage}
          component="img"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src=placeholder;
          }}
          height="208"
          image={item?.additional_photo?.url||item.url}
        />
      </Box>
      <CustomCardDate className="custom-card-date">{dayjs(item?.creation_date).format('YYYY-MM-DD')}</CustomCardDate>
      <CustomCardText className="custom-card-text">{item[`title${i18next.language}`]}</CustomCardText>
    </Card>
    </Link>
  );
}

export default CardComponent;
