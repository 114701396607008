import { Api } from "./baseUrl";
export function queryGenerator(keyword) {
  return `where[or][0][and][0][titleAm][like]=${keyword}&where[or][1][and][0][titleRu][like]=${keyword}&where[or][2][and][0][titleEn][like]=${keyword}&where[or][2][and][0][titleFr][like]=${keyword}`;
}
export const searchNews = async (keyword, limit) => {
  const {
    data: { docs, hasNextPage, totalDocs },
  } = await Api.get(`api/news?${queryGenerator(keyword, limit)}`);
  return { docs, hasNextPage, totalDocs };
};

export const searchGallery = async (keyword, limit) => {
  const {
    data: { docs, hasNextPage, totalDocs },
  } = await Api.get(`api/gallery?${queryGenerator(keyword, limit)}`);
  return { docs, hasNextPage, totalDocs };
};

export const searchAnalyses = async (keyword, limit) => {
  const {
    data: { docs, hasNextPage, totalDocs },
  } = await Api.get(`api/analyisis?${queryGenerator(keyword, limit)}`);
  return { docs, hasNextPage, totalDocs };
};

export const searchAnnauncments = async (keyword, limit) => {
  const {
    data: { docs, hasNextPage, totalDocs },
  } = await Api.get(`api/announsments?${queryGenerator(keyword, limit)}`);
  return { docs, hasNextPage, totalDocs };
};
