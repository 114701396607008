import { Box } from '@mui/system';
import React, { useEffect, useState } from "react";
import { Api } from "../../api/baseUrl";
import subImage from '../../assets/images/subscription.svg';
import { SubscriptionEmail, SubscriptionTitle, SubscriptionTouch } from "../../customText";
import { Flex, FlexColumnXCentered, JustifyCenter } from "../../models/boxes";
import CustomButton from './../../elements/button/button';
import { btnStyle, subscriptionBlock, subscriptionContent, subscriptionImage, subscriptionShadow, subscriptionTextBlock, subscriptionEmail } from './style';
import { t } from 'i18next';
import Loader from '../loader';
import placeholder from '../../assets/photo-placeholder.png'
function SubscriptionPopup() {
    const [state, setState] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [load, setLoad] = useState(false)
    useEffect(() => {
        const re = /\S+@\S+\.\S+/;
        return () => {
            if (!re.test(state)) {
                setError(true)
                setErrorMessage('Email is not valid')
            } else {
                setError(false)
                setErrorMessage('')
            }
        }
    }, [state])

    const subscribe = async () => {
        try {
            setLoad(true)
            const formData = new FormData()
            formData.append('email', state)
            const { data } = await Api.post('/emails', formData)
            setErrorMessage(data.message);
            setTimeout(() => {
                setErrorMessage('')
                setState('')
                setLoad(false)
            }, 5000)
        } catch (error) {
            setError(true)
            setErrorMessage(error.response.data.errors.map(item => item.message).join(', '));
            setTimeout(() => {
                setErrorMessage('')
                setState('')
                setLoad(false)
            }, 5000)
        }
    }
    return (
        <JustifyCenter className='subscription-email' sx={subscriptionContent}>
            <FlexColumnXCentered sx={subscriptionBlock}>
                <Flex>
                    <Box
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = placeholder;
                        }}
                        src={subImage} component='img' sx={subscriptionImage}></Box>
                </Flex>
                <Box sx={subscriptionShadow}></Box>
                <FlexColumnXCentered sx={subscriptionTextBlock}>
                    <SubscriptionTouch>{t('letsGetInTouch')}</SubscriptionTouch>
                    <SubscriptionTitle>{t('subscribtion')}</SubscriptionTitle>
                    <SubscriptionEmail helperText={errorMessage} error={error} sx={subscriptionEmail} value={state} onChange={(e) => setState(e.target.value)} label={t('email')} variant="outlined" />
                    {load ? <Box mt={1}><Loader /></Box> : <CustomButton disabled={error || !state} text={t('subscribe')} className='active' sx={btnStyle} onClick={subscribe} />}
                </FlexColumnXCentered>
            </FlexColumnXCentered>
        </JustifyCenter>
    )
}

export default SubscriptionPopup