import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { Box } from "@mui/system";
import i18next from "i18next";
import React from "react";
import { state1 } from "../../colors";
import { CustomLanguageBlock } from '../../customText';
import { AlignCenter, FlexColumn } from './../../models/boxes';
import { custtomDropDownBlockStyle, custtomDropDownstyle } from './style';

export const DropDown = ({open, current, setCurrent, data, setOpen, dropDownStyle, dropDownBlockStyle}) =>{

  const changeLanguage = (lang) => {
    
    i18next.changeLanguage(lang.lang)
    localStorage.lang = lang.lang
    localStorage.langCode = lang.code
    setCurrent(lang.code)
    window.location.reload()

  }
  return (
    <AlignCenter sx={dropDownBlockStyle ?? custtomDropDownBlockStyle} position='relative' onClick={() => setOpen(!open)}>
      <CustomLanguageBlock className="customLngBlock">
        {current}
      </CustomLanguageBlock>
      {open ?
        <KeyboardArrowUpOutlinedIcon className='arrowUp' sx={{ color: state1.colors.customGray }} />
        :
        <KeyboardArrowDownOutlinedIcon className='arrowDown' sx={{ color: state1.colors.customGray }} />}
      {open && <FlexColumn sx={dropDownStyle ?? custtomDropDownstyle}>
        {data.map((elem, i) => {
          return (
            <Box key={i} onClick={() => changeLanguage(elem)}>
              <CustomLanguageBlock className="languageBlock">{elem.name}</CustomLanguageBlock>
            </Box>
          );
        })}
      </FlexColumn>}
    </AlignCenter>
  );
}