import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomSpringDate,
  CustomSpringSubTitle,
  CustomSpringTitle,
  ReadMore,
} from "../../customText";
import { RenderOutput } from "../../utils/editorOutputGenerator";
import { AlignCenter, CustomImage } from "./../../models/boxes";
import {
  arrowRight,
  imgBlock,
  springContainer,
  springContainerHeader,
} from "./style";
import placeholder from "../../assets/photo-placeholder.png";
import dayjs from "dayjs";
function SpringCard({ item }) {
  const navigate = useNavigate();
  return (
    <Box sx={springContainer} className="spring-card">
      <Box sx={springContainerHeader}>
        <Box sx={imgBlock}>
          <CustomImage
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholder;
            }}
            className="img"
            component="img"
            src={item?.additional_photo?.url||item?.url}
          />
        </Box>
        <Box>
          <CustomSpringTitle>
            {item && item[`title${i18next.language}`]}
          </CustomSpringTitle>
          <CustomSpringDate>
            {dayjs(item?.creation_date).format('YYYY-MM-DD')}
          </CustomSpringDate>
        </Box>
      </Box>
      <Box>
        <CustomSpringSubTitle>
          <RenderOutput
            initialValue={item?.[`description${i18next.language}`]}
          />
        </CustomSpringSubTitle>
      </Box>
      <AlignCenter sx={{ marginTop: "12px" }}>
      {/* `?title=${data?.[`title${i18next.language}`]?.split(" ").join("-")}` */}
        <ReadMore onClick={() => navigate(`/post/${item.id}?title=${item?.[`title${i18next.language}`]?.split(" ").join("-")}`)}>
          {t("read")}
        </ReadMore>
        <ArrowRightAltIcon sx={arrowRight} />
      </AlignCenter>
    </Box>
  );
}

export default SpringCard;
