import { Box, useMediaQuery } from "@mui/material";
import i18next from "i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProgramTextSubTitle } from "../../customText";
import { CustomImage, Flex, SpaceBetween } from "./../../models/boxes";
import {
  animationBlock,
  imageBlock,
  mobileProgramMain,
  programMain,
  programMainText,
  programParent,
} from "./styles";
import placeholder from "../../assets/photo-placeholder.png";
const Program = ({ state }) => {
  const isLaptop = useMediaQuery("(max-width:1280px)");
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const positionTheImage = (index) => {
    setIndex(index);
  };
  return (
    <Flex sx={programParent} component="div">
      {state?.length > 0 && (
        <SpaceBetween sx={programMain} position="relative">
          <SpaceBetween width="100%" className="program-main" component="div">
            {isLaptop ? (
              <Box sx={mobileProgramMain} component="fieldset">
                {state?.map((elem, i) => {
                  return (
                    <ProgramTextSubTitle
                      onClick={() => navigate(`post/${elem.id}?title=${elem?.[`title${i18next.language}`]?.split(" ").join("-")}`)}
                      key={i}
                    >
                      {elem[`title${i18next.language}`]}
                    </ProgramTextSubTitle>
                  );
                })}
              </Box>
            ) : (
              <Box sx={programMainText}>
                {state?.map((elem, i) => {
                  return (
                    <ProgramTextSubTitle
                      onClick={() => navigate(`/post/${elem.id}?title=${elem?.[`title${i18next.language}`]?.split(" ").join("-")}`)}
                      onMouseLeave={() => positionTheImage(0)}
                      onMouseEnter={() => positionTheImage(i)}
                      key={i}
                    >
                      {elem[`title${i18next.language}`]}
                    </ProgramTextSubTitle>
                  );
                })}
              </Box>
            )}

            <Box sx={animationBlock} compoment="div">
              {state?.map((elem, i) => {
                if (index === i) {
                  return (
                    <Box
                      style={{
                        right: "0",
                        zIndex: state.length,
                        transform: `perspective(500px) translateZ(0) translateX(0)`,
                      }}
                      sx={imageBlock}
                      key={i}
                    >
                      <CustomImage
                        borderRadius={"8px 0px 0px 8px"}
                        component="img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = placeholder;
                        }}
                        src={elem?.additional_photo?.url||elem.url}
                      ></CustomImage>
                    </Box>
                  );
                }
                return (
                  <Box
                    style={{
                      right: i * "32" + "px",
                      zIndex: state.length - i,
                      transform: `perspective(500px) translateZ(${
                        -(i * 32) * 2
                      }px) translateX(${-(i * 32)}px)`,
                    }}
                    sx={imageBlock}
                    key={i}
                  >
                    <CustomImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = placeholder;
                      }}
                      borderRadius={"8px 0px 0px 8px"}
                      component="img"
                      src={elem.url}
                    ></CustomImage>
                  </Box>
                );
              })}
            </Box>
          </SpaceBetween>
        </SpaceBetween>
      )}
    </Flex>
  );
};

export default Program;
