import Carousel from "../../components/carousel/carousel";
import AboutUs from "../../components/aboutUs/aboutUs";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { getMainPageSubMenus, getPosts } from "../../api/headerApis";
import {
  MainContainer,
  SpaceBetween,
  TextCenterMobile,
} from "../../models/boxes";
import { Box, Link, SvgIcon } from "@mui/material";
import { MainPageTitle } from "../../customText";
import { apostrophe } from "../../components/springCarousel/style";
import CustomButton from "../../elements/button/button";
import { ReactComponent as Apostrophe } from "../../assets/images/appostropheBig.svg";
import { DefineHomePageComponent } from "../../components/defineHomePageComponent/defineHomePageComponent";
import { Helmet } from "react-helmet";

const Home = () => {
  const [state, setState] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const submenus = await getMainPageSubMenus();
      const data = await Promise.all(
        submenus.map(async (item) => {
          const { docs } = await getPosts(item.id);
          const section = {
            subMenuInfo: item,
            posts: docs,
          };
          return section;
        })
      );
      setState(data);
    })();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Home")}</title>
        
      </Helmet>
      <Carousel />

      <AboutUs />
      {state?.map((item) => {
        return (
          <Fragment key={item.key}>
            {item.posts.length > 0 && (
              <MainContainer>
                <Box>
                  <SpaceBetween className="main-page-title" position="relative">
                    <MainPageTitle>
                      {item?.subMenuInfo?.[`title${i18next.language}`]}
                    </MainPageTitle>
                    <SvgIcon
                      sx={apostrophe}
                      component={Apostrophe}
                      viewBox="0 0 54 54"
                    ></SvgIcon>
                  </SpaceBetween>
                  <DefineHomePageComponent
                    subMenu={item.subMenuInfo}
                    posts={item.posts}
                  />
                </Box>
                {!item?.subMenuInfo?.cardType?.card_type?.includes(
                  "Type 4"
                ) && (
                  <TextCenterMobile mt="36px">
                    <CustomButton
                      text={t("seeMore")}
                      // `?title=${data?.[`title${i18next.language}`]?.split(" ").join("-")}`
                      onClick={() => navigate(`${item.subMenuInfo.id}?title=${item?.subMenuInfo?.[`title${i18next.language}`]?.split(" ").join("-")}`)}
                    />
                  </TextCenterMobile>
                )}
              </MainContainer>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default Home;
