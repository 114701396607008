import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { JustifyCenter } from "./../../models/boxes";

function Loader({ loading=true }) {
  return loading ? (
    <JustifyCenter>
      <CircularProgress />
    </JustifyCenter>
  ) : null;
}

export default Loader;
