import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getSearchPosts, getSearchSubMenus } from "../../api/headerApis";
import CustomInput from "../../elements/input/input";
import { MainContainer } from "../../models/boxes";
import Loader from "../loader";
import TabPanelComponent from "./../tabPanelComponent/tabPanelComponent";
import {
  closeIcon,
  inputContainer
} from "./style";
function NestedSearchComponent() {
  const [searchData, setSearchData] = useState(null);
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [pageInfo, setPageInfo] = useState({ limit: 10, hasNextPage: true });
  const [isLoadSeeMore, setIsLoadSeeMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getSearchSubMenus();
      const { docs, hasNextPage } = await getSearchPosts(search);
      const newData = data.map((subMenu) => {
        const foundPosts = docs.filter((item) => subMenu?.id === item.subMenus?.id);
        return { subMenu, foundPosts };
      });
      setSearchData(newData);
      setIsLoading(false);
    };

    fetchData();
  }, [search]);

  const handleSearchChange = (newValue) => {
    setSearch(newValue);
    // Use navigate to update the URL with the new search value
    navigate(`?search=${newValue}`);
  };
  
  return (
    <Box sx={{ pt: "120px" }}>
      <MainContainer>
        <Box position="relative">
          <CustomInput
            sx={inputContainer}
            value={search}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <CloseIcon sx={closeIcon} onClick={() => setSearch("")} />
        </Box>
        {isLoading ? (
          <Box m={5}><Loader /></Box>
        ) : (
          <Box>
            {searchData && search && (
              <TabPanelComponent
                searchData={searchData}
                isLoadSeeMore={isLoadSeeMore}
                setPageInfo={setPageInfo}
                pageInfo={pageInfo}
              />
            )}

           
            {/* Harut axper esi mihat naenq jogenq inchnerisa petq us es dinamikutyan */}
            {/* <JustifyCenter>
                  <ExtraInfoTitle className="extra-info-title">
                    {t("moreInfo")}
                  </ExtraInfoTitle>
                </JustifyCenter> */}
            {/* <Box sx={extraCardContainer}>
              {news?.map((item, i) => {
                return (
                  <ExtraInfo
                    key={i}
                    cardStyle={{ mt: "32px" }}
                    showMore={t("seeMore")}
                    title={item[`title${i18next.language}`]}
                    id={item.id}
                    subTitle={
                      <RenderOutput
                        initialValue={item[`description${i18next.language}`]}
                      />
                    }
                  />
                );
              })}
            </Box> */}
          </Box>
        )}
      </MainContainer>
    </Box>
  );
}

export default NestedSearchComponent;
