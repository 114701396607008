import React from "react";
import { FlexWrap, MainContainer } from "../../models/boxes";
import { PDFTitle } from "./../../customText";
import PDFComponent from "../../components/PDFComponent/pdfcomponent";
import { PDFWrapper } from "./style"
import { t } from "i18next";
import { Helmet } from "react-helmet";

const PDFPage = () => {
  return (
    <MainContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title></title>
      </Helmet>
      <PDFTitle className="pdf-title">{t('pdfDocument')}</PDFTitle>
      <FlexWrap className="pdf-wrapper" sx={PDFWrapper}>
        <PDFComponent type={'documents'}/>
      </FlexWrap>
    </MainContainer>
  );
};

export default PDFPage;
