import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Dialog, DialogContent } from "@mui/material";
import i18next, { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/swiper-bundle.min.css";
import { Api } from "../../api/baseUrl";
import { CustomCarouselSubTitle, CustomCarouselTitle } from "../../customText";
import CustomButton from "../../elements/button/button";
import { Flex, FlexColumn } from "./../../models/boxes";
import "./module.carousel.css";
import { buttonsContainer } from "./style";
import placeholder from '../../assets/photo-placeholder.png'
export default function SimpleSlider({ vidioPlayer }) {
  const [images, setImages] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const ref= useRef()
  useEffect(() => {
    (async () => {
      const {data:{docs}} = await Api.get(`/posts?where[or][0][and][0][show_slider][equals]=true&sort=-creation_date`)
      setImages(docs)
    })();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2880
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <p>AMD 2050022721157001</p>
        </DialogContent>
      </Dialog>
      {!vidioPlayer && (
        <Slider {...settings}>
          {images?.map((elem) => {
            return (
              <React.Fragment key={elem.id}>
                <Box className="app-slider-content">
                  <Box
                    ref={ref} 
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src=placeholder;
                    }}
                    component="img"
                    src={elem.url}
                  />
                  <Box className="text-block-container">
                    <FlexColumn className="text-block">
                      <CustomCarouselTitle className="custom-carousel-title">
                        {elem[`title${i18next.language}`]}
                      </CustomCarouselTitle>
                      <Link to={`/post/${elem.id}?title=${elem?.[`title${i18next.language}`]?.split(" ").join("-")}`}>
                      <CustomCarouselSubTitle
                        mt="24px"
                      >
                        <Box mr="4px" component="span">
                          {t("seeMore")}
                        </Box>
                        <ArrowRightAltIcon fontSize="large" className="arrow-right"/>
                      </CustomCarouselSubTitle>
                      </Link>
                      <Flex
                        className="header-buttons-container"
                        sx={buttonsContainer}
                      >
                        {/* // TODO nviraberelu mas@  */}
                        {/* <CustomButton onClick={handleOpenDialog} text={t("donate")} /> */}
                        <Link to="/subscribtion">
                        <CustomButton
                          text={t("joinUs")}
                        />
                        </Link>
                      </Flex>
                    </FlexColumn>
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Slider>
      )}
    </>
  );
}
