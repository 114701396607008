import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { icon } from "./style.js";
import { AlignCenter, Flex } from "../../models/boxes.js";
import { state1 } from "../../colors.js";


function IconText({ IconName, iconText, titleTooltip, isOpen }) {
  const [show, setShow] = React.useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding:'12px 16px',
      backgroundColor: state1.colors.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[4],
      fontSize: 14,
    },
  }));


  useEffect(()=>{
    setTimeout(()=>{
      setShow(false);
    },3000)
  })

  const handleClick = () => {
      setShow(true);
  };

  return (
    <Flex className="tooltip-block" sx={icon}>
      <LightTooltip
        title={titleTooltip ? titleTooltip : null}
        open={show}
        onClick={handleClick}
      >
        <AlignCenter>
          <IconName sx={{ mr: 1 }} />
          <Box component="span">{iconText}</Box>
        </AlignCenter>
      </LightTooltip>
    </Flex>
  );
}

export default IconText;
