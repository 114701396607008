import styled from '@emotion/styled'
import { OutlinedInput } from '@mui/material'
import {state1} from '../../colors'

export const CustomInputStyle = styled(OutlinedInput)({
    borderWidth:'1px',
    borderColor:state1.colors.customGray,
    borderRadius: '8px',
    '& .MuiInputBase-input':{
        padding:'14px 12px'  
    }
})
