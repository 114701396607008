import styled from "styled-components";
import { Swiper } from 'swiper/react';
export const StyledSlider = styled(Swiper)(({
    "& .swiper-wrapper":{
        padding:'2px'
    },

    "&.swiper":{
        margin: 0
    },
    
    "& .swiper-slide-visible":{
        maxWidth: 306,
    },
    "& .swiper-slide":{
        minWidth: 306,
    },
    
    "&.swiper-slide .MuiPaper-root":{
        boxShadow: 'none',
    },
    
    "&.swiper-slide img":{
        borderRadius: 8,
    },

    "& .swiper-slide-visible":{
        maxWidth: 192
    }
}))