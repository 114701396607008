import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Dialog,
  DialogContent,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import i18next, { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { CustomHeaderLinks } from "../../customText";
import CustomButton from "../../elements/button/button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  AlignCenter,
  Flex,
  FlexColumn,
  MainContainer,
} from "../../models/boxes";
import { DropDown } from "../dropdown/dropdown";
import { SearchText } from "./../../customText";
import {
  SpaceBetween,
  FlexFullCentered,
  CustomImage,
} from "./../../models/boxes";
import {
  address,
  addressImage,
  addressImageChild,
  accordionBlock,
  appBarHeader,
  burgerBtnColor,
  burgerButton,
  headerLink,
  icon,
  logoBlock,
  menuIconBlock,
  menuResponsiveButtons,
  openedMenu,
  openedMenuBlock,
  subscribeBtn,
  popupButtonStyle,
  popupExpanded,
  popupIcon,
  popupIconInput,
  respHeaderBlock,
  respHeaderLink,
  respLogoBlock,
} from "./style";

import { getSubmenus } from "../../api/headerApis";
import Loader from "../loader";
import { SubMenus } from "./headerHovers/subMenus";
import placeholder from "../../assets/photo-placeholder.png";
import { MainMenu } from "./drawerMenu/drawerManu";
const languages = [
  {
    name: "Հայերեն",
    code: "Հայ",
    lang: "Am",
  },
  {
    name: "English",
    code: "Eng",
    lang: "En",
  },
  {
    name: "Русский",
    code: "Рус",
    lang: "Ru",
  },
  {
    name: "French",
    code: "Fr",
    lang: "Fr",
  },
];

function Header({ mainMenu, contact }) {
  const [subMenus, setSubMenus] = useState(null);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.langCode || "Հայ"
  );
  const [isSearchHovered, setSearchHovered] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentHovered, setCurrentHovered] = useState("");
  const [bgBodyColors, setBgBodyColors] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:899px)");
  let timeoutId

  const handleMouseOver = (id) => {
    timeoutId = setTimeout(async () => {
      setIsLoading(true);
      setSubMenus(null);
      setCurrentHovered(null);
      const data = await getSubmenus(id);
      setSubMenus(data);
      setIsLoading(false);
      setSearchHovered(false)
    }, 300)
  };

  const navigate = useNavigate();

  const handleMouseOut = () => {
    clearTimeout(timeoutId)
    setSubMenus(null);
    setIsLoading(false);
    setOpen(false)
    setSearchHovered(false)
  };

  const onMouseOutIfStillClosed = () => {
    clearTimeout(timeoutId)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const search = () => {
    setSearchHovered(false);
    navigate("/search?search=" + searchText);
    window.location.reload();
  };

  const changeBodyColor = () => {
    setBgBodyColors(!bgBodyColors);
    if (bgBodyColors) {
      document.body.classList.add("bg-body");
    } else {
      document.body.classList.remove("bg-body");
    }
  };

  const hoverOnContactMenu = () => {
    timeoutId = setTimeout(() => {
      handleMouseOut();
      setCurrentHovered("contact");
      setSearchHovered(false)
      setOpen(false)
    }, 200)
  };

  return (
    <>
      <MainMenu
        open={openMobileMenu}
        setOpen={setOpenMobileMenu}
        mainMenu={mainMenu}
        handleOpenDialog={handleOpenDialog}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <p>AMD 2050022721157001</p>
        </DialogContent>
      </Dialog>

      <AppBar className="header" position="sticky" sx={appBarHeader}>
        <Container maxWidth="xl">
          <Toolbar>
            <Link to={"/"}>
              <Box sx={logoBlock}>
                <Box component="img" src={Logo} />
              </Box>
            </Link>
            <Box sx={menuIconBlock}>
              <IconButton
                size="large"
                onClick={() => {
                  setOpenMobileMenu(true)
                  if (!openMobileMenu) {
                    setSearchHovered(false)
                  }
                }}
                sx={burgerBtnColor}
              >
                <MenuIcon />
              </IconButton>
              <>
                <Link to="./">
                  <Box sx={respLogoBlock}>
                    <Box component="img" src={Logo} />
                  </Box>
                </Link>
              </>
            </Box>

            {!isMobile && (
              <AlignCenter sx={headerLink}>
                <Box sx={respHeaderBlock}>
                  {mainMenu?.map((page) => (
                    <Button
                      onMouseEnter={() => handleMouseOver(page.id)}
                      onMouseLeave={onMouseOutIfStillClosed}
                      key={page.id}
                      // onClick={handleCloseNavMenu}
                      sx={respHeaderLink}
                    >
                      <CustomHeaderLinks>
                        {page[`title${i18next.language}`]}
                      </CustomHeaderLinks>
                    </Button>
                  ))}
                  <Button sx={respHeaderLink}>
                    <CustomHeaderLinks onMouseEnter={hoverOnContactMenu} onMouseLeave={onMouseOutIfStillClosed}>
                      {t("contact")}
                    </CustomHeaderLinks>
                  </Button>
                </Box>
              </AlignCenter>
            )}
            <Flex position={"relative"}>
              <SearchIcon
                onMouseEnter={() => {
                  timeoutId = setTimeout(() => {
                    setSearchHovered(true)
                    setCurrentHovered(null)
                    setOpen(false)
                  }, 200)
                }}
                onMouseLeave={onMouseOutIfStillClosed}
                className="search-icon"
                sx={[icon, { mr: 2 }]}
              />
            </Flex>
            <DropDown
              className="drop-down-text"
              data={languages}
              open={open}
              setOpen={setOpen}
              current={currentLanguage}
              setCurrent={setCurrentLanguage}
            />
            <Flex onClick={changeBodyColor}>
              {bgBodyColors ? (
                <VisibilityIcon sx={[icon, { ml: 2 }]} />
              ) : (
                <VisibilityOffIcon sx={[icon, { ml: 2 }]} />
              )}
            </Flex>
          </Toolbar>
        </Container>
        {!isMobile && (
          <Box
            className={
              currentHovered === "contact" ? "opened-search-menu" : null
            }
            sx={openedMenu}
            onMouseLeave={() => setCurrentHovered(null)}
          >
            <FlexFullCentered sx={openedMenuBlock}>
              <SpaceBetween width={"100%"} gap={3} alignItems={"center"}>
                <Box sx={address}>
                  {contact?.map((item) => (
                    <Box component="span" key={item.id}>
                      <br />
                      {item[`state${i18next.language}`]} <br />{" "}
                      {item[`address${i18next.language}`]} <br /> {t("phone")}{" "}
                      {item.phone} <br />
                    </Box>
                  ))}

                  <Box component="span">
                    <br />
                    <br />
                    {t("email")}
                    <b>info@dcp.am</b>
                  </Box>
                </Box>
                <Box sx={addressImage}>
                  <Box sx={addressImageChild}>
                    <Button
                      component="span"
                      sx={subscribeBtn}
                      onClick={() => navigate("/subscribtion")}
                    >
                      {t("subscribe")}
                      <ArrowRightAltIcon fontSize="large" />
                    </Button>
                  </Box>
                  {contact?.[0]?.url && (
                    <CustomImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = placeholder;
                      }}
                      component="img"
                      src={contact?.[0]?.url}
                    />
                  )}
                </Box>
              </SpaceBetween>
            </FlexFullCentered>
          </Box>
        )}
        {!isMobile && (
          <Box
            className={subMenus ? "opened-search-menu" : null}
            sx={openedMenu}
            onMouseLeave={handleMouseOut}
          >
            {!isLoading ? (
              <SubMenus subMenus={subMenus} handleMouseOut={handleMouseOut} />
            ) : (
              <Loader />
            )}
          </Box>
        )}
      </AppBar>

      <Box
        className={isSearchHovered ? "search-menu" : null}
        sx={popupExpanded}
      >
        <MainContainer>
          <SpaceBetween alignItems="center">
            <KeyboardBackspaceIcon
              sx={popupIcon}
              onClick={() => setSearchHovered(false)}
            ></KeyboardBackspaceIcon>
            <SearchText>{t("search")}</SearchText>
            <CloseIcon
              sx={popupIcon}
              onClick={() => setSearchHovered(false)}
            ></CloseIcon>
          </SpaceBetween>
        </MainContainer>
        <MainContainer position="relative">
          <TextField
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            sx={{ pb: "7.5%" }}
            fullWidth
            id="outlined-multiline-flexible"
            variant="standard"
            InputProps={{
              sx: { pl: "12px", pr: "100px" },
            }}
          />

          <CloseIcon
            onClick={() => setSearchText("")}
            sx={[icon, popupIconInput, { mr: "44px" }]}
          ></CloseIcon>
          <SearchIcon onClick={search} sx={[icon, popupIconInput]}></SearchIcon>

          <FlexColumn>
            <CustomButton
              className="active"
              customizeButton={popupButtonStyle}
              onClick={() => navigate("/subscribtion")}
              text={t("joinUs")}
            />

            {/* // TODO nviraberelu mas@  */}
            {/* <CustomButton
              className="active"
              customizeButton={popupButtonStyle}
              onClick={handleClickOpen}
              text={t("donate")}
            /> */}
          </FlexColumn>
        </MainContainer>
      </Box>
    </>
  );
}
export default Header;
