import { state1 } from "../../colors";

export const CustomCardContentImg = {
  padding: "0px",
  margin:"12px",
  borderRadius:"8px"
};

export const CustomCardContent = {
  padding: "0px 12px 24px 12px",

  "& .admin-editor > div" : {
    whiteSpace:'unset !important'
  },

    "& .admin-editor .admin-editor-ident" : {
      paddingLeft:0,
  },
};

export const CustomCard = {
  borderRadius: "8px",
  maxWidth: "240px",

  "&:hover .custom-card-image": {
    transform: "scale(1.2)",
  },

  "& .custom-card-date, & .custom-card-content, & .custom-card-name, & .custom-card-text": {
    transition: ".3s",
  },

  "&:hover .custom-card-date, &:hover .custom-card-content, &:hover .custom-card-name, &:hover .custom-card-text": {
    color: state1.colors.customBlue,
  },

  "& .MuiCardContent-root": {
    overflow: "hidden",
  }
};

export const CustomCardImage = {
  borderRadius: "8px",
  transition: ".3s",
};
