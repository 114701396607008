import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const Flex = styled(Box)(() => ({
    display: 'flex'
}))

export const AlignCenter = styled(Flex)(() => ({
    alignItems: 'center'
}))

export const SpaceBetween = styled(Flex)(() => ({
    justifyContent: 'space-between'
}))

export const JustifyCenter = styled(Flex)(() => ({
    justifyContent: 'center'
}))

export const FlexEnd = styled(Flex)(() => ({
    justifyContent: 'end'
}))

export const FlexStart = styled(Flex)(() => ({
    justifyContent: 'flex-start'
}))

export const FlexWrap = styled(Flex)(()=>({
    flexWrap:'wrap'
}))

export const SpaceAround = styled(Flex)(() => ({
    justifyContent: 'space-around'
}))

export const FlexColumn = styled(Flex)(() => ({
    flexDirection: 'column'
}))

export const FlexBaseLine= styled(Flex)(() => ({
    alignItems: 'baseline'
}))


export const FlexColumnXCentered = styled(FlexColumn)(() => ({
    alignItems: 'center'
}))

export const FlexColumnYCentered = styled(FlexColumn)(() => ({
    justifyContent: 'center'
}))

export const FlexFullCentered = styled(FlexColumnYCentered)(() => ({
    alignItems: 'center'
}))

export const CustomImage = styled(Box)(()=>({
    objectFit:'cover',
    width:'100%',
    height:'100%'
}))

export const MainContainer = styled(Box)(()=>({
    maxWidth:'1440px',
    margin:'0 auto 72px auto',
    padding:'0 5%',
}))


export const TextCenterMobile = styled(Box)(()=>({
    textAlign:'left',
    '@media (max-width : 768px)': {
        textAlign:'center'
     },
}))