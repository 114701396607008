export const ru = {
  about: "О нас",
  party: "Активность",
  projects: "Проекты",
  information: "Информация",
  contact: "Контакты",
  subscribe: "Подписаться",
  download:"Скачать",
  donate: "Пожертвовать",
  seeMore: "Узнать больше",
  joinUs: "Присоединяйтесь к нам",
  program: "Программа",
  internationalRelations: "Международные отношения",
  politicalScience: "Политическая наука",
  militaryScience: "Военная наука",
  politicalEconomy: "Политическая экономия",
  socioPolitical: "Социополитическая",
  artsakhIssue: "Вопрос Арцаха",
  diaspora: "диаспора",
  politicsProgramm: "Программа поддержки сообществ",
  gallery: "галерея",
  news: "Новости",
  publications: "публикации",
  analysis: "Анализы",
  team: "Наша команда",
  read: "Читать",
  ideology: "Идеология",
  charter: "устав",
  email: "Электронная почта",
  search: "Поиск",
  phone: "Телефон",
  leaveAComment: "Оставить комментарий",
  nameSurname: "Имя Фамилия",
  comment: "комментарий",
  comments: "Комментарии",
  moreInfo: "Вы можете найти больше информации здесь",
  searchResult: "результат поиска",
  byQuery: "по запросу",
  print: "Распечатать",
  linkCopied: "Ссылка скопирована",
  copyLink: "Копировать ссылку",
  pdf: "PDF",
  subscribtion: "Подписка",
  letsGetInTouch: "Давайте свяжемся",
  pdfDocument: 'PDF документ',
  pdfBooks: 'PDF книги',
  // SEO

  Home: "Главная - Демократическая консолидация",
};
