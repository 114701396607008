import React from "react";
import { TitleDate, CurrentDate } from "../../customText";
import { Flex, AlignCenter } from "../../models/boxes";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  container,
  currentDateStyle,
  icon,
  titleDate,
  calendarStyle,
} from "./style";
import { Box, ClickAwayListener, IconButton } from "@mui/material";
import { RangeCalendar } from "@adobe/react-spectrum";
import { defaultTheme, Provider } from "@adobe/react-spectrum";
import { useState } from "react";
import { t } from "i18next";
import './title-search.css'

export const TitleSearchDate = ({
  currentTitle,
  currentDate,
  setDates,
  searchValue,
  onChangeSearchValue,
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  return (
    <AlignCenter sx={container}>
      <Flex sx={titleDate}>
        <TitleDate>{currentTitle}</TitleDate>
        <CurrentDate sx={currentDateStyle}>{currentDate}</CurrentDate>
      </Flex>
      <Flex alignItems={"center"}>
            <div className="container">
            <div className="search-form">
                    <input onChange={(e) => onChangeSearchValue(e.target.value)} value={searchValue} type="text" className="search-field" placeholder={t('search')} />
            </div>
            </div>

        <Box position={"relative"}>
          <IconButton onClick={() => setOpenCalendar(true)}>
            <CalendarMonthIcon sx={icon} />
          </IconButton>
          {openCalendar && (
            <ClickAwayListener onClickAway={() => setOpenCalendar(false)}>
              <Box sx={calendarStyle}>
                <Provider theme={defaultTheme}>
                  <RangeCalendar
                    onChange={(e) =>
                      setDates({
                        start: new Date(
                          `${e.start.year}-${e.start.month}-${e.start.day}`
                        ).toISOString(),
                        end: new Date(
                          `${e.end.year}-${e.end.month}-${e.end.day}`
                        ).toISOString(),
                      })
                    }
                  />
                </Provider>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </Flex>
    </AlignCenter>
  );
};
