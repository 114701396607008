import React from "react";

import NestedComponentUnique from "../NestedComponentUnique";

function AnnouncementComponent() {
  return (
    <NestedComponentUnique />
  );
}

export default AnnouncementComponent;
