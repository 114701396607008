import { state1 } from './../../colors';

export const tabContainer = {
    width:'100%',
    marginTop:'66px',
    marginBottom:'32px'
}

export const tabDivder = {
    borderBottom: 1,
    borderColor: state1.colors.customBlack
}

export const tabButton ={
    textTransform:'capitalize',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    color:state1.colors.customBlack,
    '&.Mui-selected':{
        color:state1.colors.customBlue,
    },
}

export const tabList = {
    '& .MuiTabs-indicator':{
        backgroundColor:state1.colors.customBlue
    }
}
