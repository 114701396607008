import React, { useRef } from 'react';
import { Box } from '@mui/system';
import { CustomImage } from './../../models/boxes';
import { peopleBox, peopleContainer } from './style';
import { PeopleNameSurname, PeoplePosition} from '../../customText';
import { Link } from 'react-router-dom';
import placeholder from '../../assets/person-placeholder.png'
function People (props){
    const ref = useRef()
    return (
        <Link to={`/staff/${props.id}`}>
        <Box className='people-container' sx={peopleContainer} component='div'>
            <Box className='people-box' component='div' sx={peopleBox}>
               <CustomImage 
                ref={ref} 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=placeholder;
                  }}
                component='img'
                src={props.url}/>
            </Box>
            <PeopleNameSurname>{props.name}</PeopleNameSurname>
            <PeoplePosition>{props.position}</PeoplePosition>
        </Box>  
        </Link>
    )
}

export default People