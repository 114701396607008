import { Box } from "@mui/system";
import i18next, { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { Api } from "../../api/baseUrl";
import {
  NestedNewsDate,
  NestedNewsTitle,
  NestedVidioText,
} from "../../customText";
import CustomButton from "../../elements/button/button";
import { CustomImage, JustifyCenter, MainContainer } from "../../models/boxes";
import DocumentIconsGroup from "../../shared/documentIconsGroup/documentIconsGroup";
import VideoPlayer from "../../shared/videoPlayer/videoPlayer";
import { RenderOutput } from "../../utils/editorOutputGenerator";
import CommentForm from "../commentForm/commentForm";
import { StyledSliderAnalysis } from "../sliderMuiAnalysis/style";
import { imageContent, imagesBlock, imagesContainer } from "./style";
import placeholder from "../../assets/photo-placeholder.png";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

function NestedComponentUnique() {
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const pdfRef = useRef();
  const [isPlaying,setIsPlaying] = useState(0)
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const { data } = await Api.get(`/posts/${postId}?depth=2`);
      // navigate(
      //   `?title=${data?.[`title${i18next.language}`]?.split(" ").join("-")}`
      // );
      setPost(data);
    })();
  }, [postId]);

  return (
    <Box sx={{ mt: "72px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{post?.[`title${i18next.language}`]}</title>
      </Helmet>
      <MainContainer>
        <NestedNewsDate>
          {dayjs(post?.creation_date).format("YYYY-MM-DD")}
        </NestedNewsDate>
        <NestedNewsTitle>{post?.[`title${i18next.language}`]}</NestedNewsTitle>
      </MainContainer>
      {post && post?.show_videos ? (
        <StyledSliderAnalysis
          pagination={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          className="analysis-component vidio-lib"
          slidesPerView={1}
          onSlideChange={() => setIsPlaying(prev=>prev+1)}
          
        >
          {[
            ...post.youTube.map((item) => ({
              video: item.youTube,
              id: item.id,
            })),
            ...post.fbVideo.map((item) => ({
              video: item.fbVideo,
              id: item.id,
            })),
          ]?.map((item, i) => {
            return (
              <SwiperSlide key={item.id}>
                <VideoPlayer url={item.video} key={isPlaying}/>
              </SwiperSlide>
            );
          })}
        </StyledSliderAnalysis>
      ) : null}
      {(post?.photos.length > 0) & post?.show_photos ? (
        <Box className="image-block" sx={imagesBlock}>
          <Box sx={imagesContainer}>
            {post?.photos.map((item, i) => {
              return (
                <CustomImage
                  key={i}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = placeholder;
                  }}
                  component="img"
                  src={item.photo.url}
                  sx={imageContent}
                />
              );
            })}
          </Box>
        </Box>
      ) : null}
      <MainContainer>
        <NestedVidioText ref={pdfRef}>
          <RenderOutput
            initialValue={post?.[`description${i18next.language}`]}
          />
        </NestedVidioText>
        <DocumentIconsGroup
          componentRef={pdfRef}
          printTitle={post?.[`title${i18next.language}`]}
        />
      </MainContainer>
      <CommentForm />
      <MainContainer>
        <JustifyCenter mt="36px">
          <CustomButton buttonStyle text={t("seeMore")} />
        </JustifyCenter>
      </MainContainer>
    </Box>
  );
}

export default NestedComponentUnique;
