import { state1 } from '../../colors';

export const springContainer = {
    width:'390px',
    height:'192px',
    backgroundColor:state1.colors.white,
    padding:'12px 16px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
    borderRadius:'8px',
    '@media (max-width : 768px)': {
        maxWidth:'300px'
    },

    '@media (max-width : 428px)': {
        maxWidth:'200px'
    }
}

export const springContainerHeader = {
    display:'grid',
    gridTemplateColumns:"auto 1fr",
    '@media (max-width : 768px)': {
        minWidth: '200px',
        width: 'auto',
        height: 'auto',
   }
}

export const imgBlock = {
    width:'56px',
    height:'56px',
    marginRight:'8px',
    '& .img': {
        borderRadius:'50%',
        objectFit:'cover'
    }
}

export const arrowRight = {
    color:state1.colors.customGray
} 