import { state1 } from "./colors";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

// HOME PAGEopened-menu-block 

// Header Component

export const CustomHeaderLinks = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22px",
  textTransform: "capitalize",
  transition: "0.3s",
  color: state1.colors.customBlack,
  "&:hover": {
    color: state1.colors.customBlue,
  },
}));

export const CustomLanguageBlock = styled(Typography)(() => ({
  cursor: "pointer",
  padding: "5px",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  transition: "0.3s",
  color: state1.colors.customGray,
}));

// Carousel Component

export const CustomCarouselTitle = styled(Typography)(() => ({
  maxWidth: "825px",
  width: "100%",
  display: "block",
  fontSize: "32px",
  fontWeight: "700",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: state1.colors.white,
  "@media (max-width : 1280px)": {
    maxWidth: "600px",
    fontSize: "24px",
  },
  "@media (max-width : 768px)": {
    maxWidth: "none",
    fontSize: "16px",
  },
}));

export const CustomCarouselSubTitle = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: state1.colors.white,
  cursor: "pointer",

  "& .arrow-right" : {
    transition: "0.3s",
  },
  
  "&:hover .arrow-right": {
    transform: "scaleX(1.5)",
    transformOrigin: "left",
  }
}));

// Program Component

export const ProgramTextTitle = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "32px",
  color: state1.colors.customBlack,
  "@media (max-width: 1280px)": {
    fontSize: "24px",
  },
  "@media (max-width: 768px)": {
    fontSize: "16px",
  },
}));

export const PartyProgrammTitle = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "32px",
  textAlign: "center",
  color: state1.colors.customBlack,
  "@media (max-width: 1024px)": {
    fontSize: "24px",
  },
}));

export const PartyProgrammSubTitle = styled(Typography)(() => ({
  margin: "4.5% auto",
  lineHeight: 2,
  color: state1.colors.customBlack,
  "@media (max-width: 1024px)": {
    fontSize: "14px",
  },
}));

export const ProgramTextSubTitle = styled(Typography)(() => ({
  paddingTop:"32px",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "33px",
  color: state1.colors.customGray,
  transition: "0.3s",
  cursor: "pointer",
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',

  "&:hover": {
    color: state1.colors.customBlack,
  },
  "@media (max-width: 1280px)": {
    color: state1.colors.customBlack,
    whiteSpace:'normal'
  },
  "@media (max-width: 768px)": {
    paddingTop:"16px",
    fontSize: "16px",
  },
}));

// PeopleBox container

export const PeopleNameSurname = styled(Typography)(() => ({
  marginTop: "8px",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "28px",
  color: state1.colors.white,
}));

export const PeoplePosition = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "12px",
  maxWidth:'224px',
  color: state1.colors.white,
}));

// Nested People Box Component

export const NestedPeopleTitle = styled(Typography)(() => ({
  fontSize: "32px",
  fontWeight: "700",
  color: state1.colors.customBlack,
}));


export const PeopleBiography = styled(Typography)(() => ({
  margin: "4.5% auto",
  textAlign: "center",
  lineHeight: 2,
  color: state1.colors.customBlack,
  "@media (max-width: 1024px)": {
    fontSize: "14px",
  },
}));


export const PeopleBiographyName = styled(Typography)(() => ({
  fontSize: "32px",
  fontWeight: "700",
  textAlign:'center',
  color: state1.colors.customBlack,
}));


export const PeopleBiographyPosition = styled(Typography)(() => ({
  fontSize: "24px",
  fontWeight: "700",
  textAlign:'center',
  color: state1.colors.customGray,
}));


// MainPageTitle

export const MainPageTitle = styled(Typography)(() => ({
  width:"min-content",
  paddingBottom: "24px",
  fontWeight: "700",
  fontSize: "32px",
  color: state1.colors.customBlack,
  "@media (max-width: 768px)": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: "24px",
  },
}));

// Main Page Card Titles

export const CustomCardDate = styled(Typography)(() => ({
  paddingTop: "12px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: state1.colors.customGray,
}));

export const CustomCardText = styled(Typography)(() => ({
  display: '-webkit-inline-box',
  paddingTop: "12px",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: 1.2,
  color: state1.colors.customBlack,
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': "1",
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
}));

export const CustomCardName = styled(Typography)(() => ({
  display: '-webkit-inline-box',
  paddingTop: "16px",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight:1.2,
  color: state1.colors.customBlack,
  wordBreak:'break-word',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': "3",
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
}));

// NESTED VIDIO LIBRARY

// Search date title

export const TitleDate = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "32px",
  color: state1.colors.customBlack,
}));

export const CurrentDate = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "32px",
  color: state1.colors.customGray,
}));

// Per Vidio page

export const NestedVidioTitle = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "24px",
  color: state1.colors.black,
}));

export const NestedVidioText = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "16px",
  color: state1.colors.black,
}));

// comment page

export const CommentTitle = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "24px",
  color: state1.colors.black,
}));

export const ErrorMessage = styled(Typography)(() => ({
  marginTop: "12px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: state1.colors.errorColor,
}));

export const Comment = styled(Typography)(() => ({
  position: "sticky",
  top: "0",
  fontWeight: "400",
  fontSize: "24px",
  color: state1.colors.black,
  backgroundColor: state1.colors.white,
}));

export const Commenter = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  color: state1.colors.black,
}));

export const Comments = styled(Typography)(() => ({
  maxWidth: "400px",
  fontWeight: "400",
  fontSize: "14px",
  color: state1.colors.customGray,
  wordBreak: "break-all",
}));

// News page

export const NewsBodyTitle = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "24px",
  color: state1.colors.customBlack,
}));

export const NewsSubBodyTitle = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: state1.colors.customGray,
}));

export const NewsBodyText = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "22px",
  wordBreak: "break-all",
  color: state1.colors.black,
}));

// NestedNews page

export const NestedNewsDate = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "22px",
  color: state1.colors.customGray,
}));

export const NestedNewsTitle = styled(Typography)(() => ({
  marginTop: "25px",
  fontWeight: "700",
  fontSize: "24px",
  color: state1.colors.black,
}));

// Search Page

export const SearchText = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "32px",
  color: state1.colors.customBlack,
}));

export const PopupResult = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "24px",
  color: state1.colors.customBlack,
  "@media (max-width: 768px)": {
    fontSize: "16px",
  },
}));

export const ResultText = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  wordBreak:"break-word",
  color: state1.colors.customBlack,
}));

export const ExtraInfoTitle = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "29px",
  letterSpacing: "0.5px",
  color: state1.colors.boldGray,
  "@media (max-width: 768px)": {
    fontSize: "16px",
  },
}));

export const ExInfoCardTitle = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "24px",
  color: state1.colors.customBlack,
}));

export const ExInfoCardSubTitle = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: state1.colors.customGray,
  transition: "0.3s",
}));

// TODO CustomCarouselSubTitle component@ piti darna ShowMoreWidthArrow component u ogtagorcvi mi tex

export const ShowMoreWidthArrow = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: state1.colors.customGray,
  transition: "0.3s",
  cursor: "pointer",
}));

// SpringCarousel texts
export const CustomSpringName = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "0.14994px",
  color: state1.colors.customBlack,
}));

export const CustomSpringDate = styled(Typography)(() => ({
  marginTop: "8px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  color: state1.colors.customGray,
}));

export const CustomSpringTitle = styled(Typography)(() => ({
  height:'20px',
  display: '-webkit-box',
  marginTop: "12px",
  fontWeight: "700",
  fontSize: "12px",
  lineHeight: "20px",
  color: state1.colors.customBlack,
  opacity: "0.87",
  textOverflow: 'ellipsis',
 '-webkit-line-clamp': '1',
 '-webkit-box-orient':'vertical',
  overflow: 'hidden',
}));

export const CustomSpringSubTitle = styled(Typography)(() => ({
  display: '-webkit-box',
  marginTop: "8px",
  fontWeight: "400",
  fontSize: "12px",
  color: state1.colors.customBlack,
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': "3",
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
}));

//header opened menu search texts and links

export const CustomOpenedMenuLink = styled(Typography)(() => ({
  display: 'inline',
  maxWidth:'50%',
  marginTop: "12px",
  paddingBottom:'2px',
  backgroundImage: 'linear-gradient(#000 0 0)',
  backgroundPosition: '0 100%', 
  backgroundSize: '0% 2px',
  backgroundRepeat: 'no-repeat',
  fontWeight: "500",
  fontSize: "24px",
  color: state1.colors.customBlack,
  transition:'background-size 0.3s, background-position 0s 0.3s',
  cursor: "pointer",
  
  "&:hover": {
    backgroundPosition: '100% 100%', /*OR bottom right*/
    backgroundSize: '100% 2px',
  },
  "&.active": {
    borderBottomColor: state1.colors.customBlack,
  },
}));

export const CustomOpenedMenuText = styled(Typography)(() => ({
  width: "380px",
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: "300",
  fontSize: "24px",
  fontStyle: "normal",
  lineHeight: "33px",
  textAlign: "center",
  color: state1.colors.customBlack,
  cursor: "pointer",
}));

export const CustomInformationText = styled(Typography)(() => ({
  marginTop: "8px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: state1.colors.customBlack,
}));

export const ReadMore = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "12px",
  color: state1.colors.customGray,
  cursor: "pointer",
  "&:hover": {
    color: state1.colors.customBlue,
  },
}));

// Subscription Text

export const SubscriptionTouch = styled(Typography)(() => ({
  marginTop: "48px",
  fontWeight: "700",
  fontSize: "12px",
  lineHeight: "16px",
  color: state1.colors.customBlue,
}));

export const SubscriptionTitle = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "32px",
  color: state1.colors.customBlack,
  "@media (max-width : 768px)": {
    fontSize: "24px",
  },
}));

export const SubscriptionSubTitle = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: state1.colors.customGray,
}));

export const SubscriptionEmail = styled(TextField)(() => ({
  marginTop: "48px",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "24px",
  color: state1.colors.customGray,
}));

export const SendAndEmail = styled(Typography)(() => ({
  marginTop: "21px",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "15px",
  color: state1.colors.customBlue,
  textDecoration: "underline",
  cursor: "pointer",
}));

export const PDFTitle = styled(Typography)(() => ({
    margin:"24px auto",
    fontSize:"32px",
    fontWeight:"bold",
    textAlign: "center",
}));

export const PDFComponentTitle = styled(Typography)(() => ({
  display: '-webkit-box',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': "3",
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
}));

export const PDFComponentSubTitle = styled(Typography)(() => ({
  display: '-webkit-box',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': "3",
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
}));