export const en = {
  about: "About us",
  party: "Activity",
  projects: "Projects",
  information: "Information",
  contact: "Contact",
  subscribe: "Subscribe",
  download:"Download",
  donate: "Donate",
  seeMore: "See more",
  joinUs: "Join us",
  program: "Program",
  internationalRelations: "International Relations",
  politicalScience: "Political Science",
  militaryScience: "Military Science",
  politicalEconomy: "Political Economy",
  socioPolitical: "Sociopolitical",
  artsakhIssue: "Artsakh Issue",
  diaspora: "diaspora",
  politicsProgramm: "Community support program",
  gallery: "gallery",
  news: "News",
  publications: "publications",
  analysis: "Analyzes",
  team: "Our team",
  read: "Read",
  ideology: "Ideology",
  charter: "Charter",
  email: "Email",
  search: "Search",
  phone: "Phone",
  leaveAComment: "Leave A Comment",
  nameSurname: "Name Surname",
  comment: "comment",
  comments: "comments",
  moreInfo: "You can find more information here",
  searchResult: "search result",
  byQuery: "by query",
  print: "Print",
  linkCopied: "Link copied",
  copyLink: "Copy the link",
  pdf: "PDF",
  subscribtion:"Subscribtion",
  letsGetInTouch:"Let's get in touch",
  pdfDocument: 'PDF document',
  pdfBooks: 'PDF books',
  // SEO
  
  Home: "Home - Democratic Consolidation",
};
