import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import { createEditor } from "slate";
import {
  DefaultElement,
  DefaultLeaf,
  Editable,
  Slate,
  withReact,
} from "slate-react";
import { slateToHtml, payloadSlateToDomConfig } from "slate-serializers";
import { adminEditor } from "./style";

export const RenderOutput = ({ initialValue }) => {
  const serializedToHtml = slateToHtml(initialValue, payloadSlateToDomConfig);

  const [editor] = useState(() => withReact(createEditor()));

  const renderElement = useCallback((props) => {

    switch (props.element.type) {
      case "indent":
        return <span className="admin-editor-ident">{props.children}</span>;
      case "block-quote":
        return <blockquote {...props.attributes}>{props.children}</blockquote>;
      case "ul":
        return <ul {...props.attributes}>{props.children}</ul>;
      case "h1":
        return <h1 {...props.attributes}>{props.children}</h1>;
      case "h2":
        return <h2 {...props.attributes}>{props.children}</h2>;
      case "h3":
        return <h3 {...props.attributes}>{props.children}</h3>;
      case "h4":
        return <h4 {...props.attributes}>{props.children}</h4>;
      case "h5":
        return <h5 {...props.attributes}>{props.children}</h5>;
      case "h6":
        return <h6 {...props.attributes}>{props.children}</h6>;
      case "li":
        return <li {...props.attributes}>{props.children}</li>;
      case "ol":
        return <ol {...props.attributes}>{props.children}</ol>;
      case "upload":
        return <img src={props.element.value.url} />;
      case "link":
        if(!props?.children?.[0]?.props?.text?.text){
          return null
        }else {
          return (
            <a {...props.attributes} href={props.element.url}>
            {props.children}
          </a>
        );
      }
      default:
        return <DefaultElement {...props}/>;
    }
  }, []);

  const renderLeaf = useCallback((props) => {
    if (props.leaf.bold && props.leaf.italic) {
      return <strong><em>{props.children}</em></strong>;
    }

    if (props.leaf.bold) {
      return <strong>{props.children}</strong>;
    }

    if (props.leaf.code) {
      return <code>{props.children}</code>;
    }

    if (props.leaf.italic) {
      return <em>{props.children}</em>;
    }

    if (props.leaf.underline) {
      return <u>{props.children}</u>;
    }
    if (props.leaf.strikethrough) {
      return <s>{props.children}</s>;
    }

    return <DefaultLeaf {...props} />;
  }, []);

  return (
    <Box className="admin-editor" sx={adminEditor}>
      {initialValue && (
        <Slate editor={editor} initialValue={initialValue}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly
          />
        </Slate>
      )}
      {/* //TODO navsyaki to mna regexi hamar */}
      {/* {initialValue && <div dangerouslySetInnerHTML={{__html: serializedToHtml.replace(/<[^/>][^>]*><\/[^>]+>/gim, '<br/>')}}/>} */}
    </Box>
  );
};
