import { state1 } from "../../colors";

export const AboutPeople = {
  paddingTop: "72px",
  "@media (max-width:1280px)": {
    "& p": {
      textAlign:'center'
    }
  },
};

export const AboutPeopleBox = {
  maxWidth: "800px",
  margin: "4.5% auto",
  columnGap: "20px",
  rowGap: "20px",
  justifyContent: "space-between",
  "& .people-container": {
    margin: "auto",
    textAlign: "center",
    "& .people-box": {
      margin: "auto",

      "& ~ p": {
        color: state1.colors.customBlack,
      }
    },
  },
};
